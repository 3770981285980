import Vue from 'vue';
import axiosLib from 'axios';
import axios from '@/plugins/axios';

import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';

import { decode as QuotedPrintable } from 'quoted-printable';

const webApi = axios();

// console.log('!![LIVE/INDEX.JS:7]', webApi.defaults.baseURL);

const LIVE_WEBSOCKET = process.env.VUE_APP_CHAT_WEBSOCKET.replace(/\/+$/, '');
const LIVE_API = process.env.VUE_APP_CHAT_API_URL.replace(/\/+$/, '');
const LIVE_PUBLIC = process.env.VUE_APP_CHAT_PUBLIC.replace(/\/+$/, '');

const webchatRequest = axiosLib.create({
  baseURL: LIVE_API,
  headers: { Authorization: 'teste' },
});

// class LiveService {
//   constructor() {
//     this._live = null;
//     this._hasNotifications = false;
//     this._online = false;
//     this._history = [];
//     this._messages = [];
//   }

//   get hasNotifications() {
//     return this._hasNotifications;
//   }

//   get online() {
//     return this._online;
//   }

//   get history() {
//     return this._history;
//   }

//   get messages() {
//     return this._messages;
//   }

//   setup() {
//     this._live = {};

//     let i = 0;

//     setInterval(() => {
//       i += 1;

//       this._history.push({ status: 'open', text: 'foo bar' });
//       this._messages.push({
//         id: i,
//         from: 'teste',
//         sentAt: new Date(),
//         text: 'foo bar baz',
//       });

//       console.log('this._messages.length', this._messages.length);
//     }, 3000);
//   }
// }

// export default new LiveService();

let _launched = false;

const _events = {};
const videoExtensions = /\.(3g2|3gp|amv|avi|gifv|mov|m4v|mp4|mpe?g|ogv|webm)($|\?)/i;
const imageExtensions = /\.(gif|jpe?g|png|svg|tiff?|webp)($|\?)/i;
const audioExtensions = /\.(caf|flac|midi?|mp3|ogg|oga|weba)($|\?)/i;
const pdfExtensions = /\.pdf($|\?)/i;

export default new Vue({
  emits: {
    requestRoom: null,
  },
  watch: {
    volume(value) {
      localStorage.setItem(`yup_chat_volume:${this.userId}`, value);
    },
  },
  computed: {
    hasList() {
      return this.history.filter((room) => room && (
        (room.status === 'QUEUE' && room.type === 'my-listing') ||
        (room.status === 'OPEN' && room.type === 'me')
      )).length > 0;
    },
    managed() {
      console.log('$live.profiles', this.$live.profiles);
      return this.$live.profiles.indexOf('ADMINISTRATOR') !== -1 || this.$live.profiles.indexOf('MANAGER') !== -1;
    },
    widget() {
      return process.env.VUE_APP_CHAT_WIDGET.replace(/\/+$/, '');
    },
    queue() {
      // Se o agente selecionar um departamento especifico irá exibir somente a fila de tal departamento
      if (this.queueCurrentDepartment) {
        return this.queueDepartments[this.queueCurrentDepartment].size ?? 0;
      }

      // total de pessoas na fila de todos departamentos
      return this.queueTotal;
    },
    unread() {
      const news = this.newMessages;
      const history = this.history;
      let total = 0;

      Object.keys(news).forEach((roomId) => {
        if (history.some((room) => room.roomId === roomId && room.type === 'me')) {
          total += news[roomId];
        }
      });

      return total;
    },
    accountId() {
      return this.contextApp?.$store?.state?.account?.id;
    },
    isMeta() {
      if (!this.accountId || !this.contextApp?.$whatsAppMetaAccounts) return false;

      console.log('[isMeta#1]', this.contextApp.$store?.state?.account?.['whitelabel_id'] === null);
      console.log('[isMeta#2]', this.contextApp.$whatsAppMetaAccounts.indexOf(this.accountId) !== -1);

      return (
        this.contextApp.$store?.state?.account?.['whitelabel_id'] === null ||
        this.contextApp.$whatsAppMetaAccounts.indexOf(this.accountId) !== -1
      );
    },
    is360() {
      return this.whatsApp360;
    },
    accountToGeneral() {
      return new RegExp(`(^|[^\\a-z\\d\\-])[*]${this.accountId}[*]([^\\a-z\\d\\-]|$)`);
    },
    userId() {
      return this.contextApp?.$store?.state?.auth?.user?.id;
    },
    hasNotifications() {
      // console.log('queueTotal:', this.queueTotal > 0, 'pendings.length:', this.pendings.length > 0, 'unread:', this.unread > 0);
      this.refreshTitle();

      return (
        this.queueTotal > 0 ||
        this.pendings.length > 0 ||
        this.unread > 0 ||
        this.history.some((r) => r.type === 'my-listing')
      );
    },
    requestSimulator() {
      let domain = process.env?.VUE_APP_CHAT_WIDGET;

      if (domain) domain = domain.replace(/\/+$/, '');

      const html = [
        '<!DOCTYPE html>',
        '<html>',
        '<head>',
        '<meta http-equiv="Content-Type" content="text/html; charset=utf-8">',
        '<meta http-equiv="X-UA-Compatible" content="IE=edge">',
        '<meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no">',
        '</head>',
        '<body>',
        '<svg xmlns="http://www.w3.org/2000/svg" id="svg965" viewBox="0 0 2100.8 1822.4" width="100%" y="0px" x="0px">',
        '<g id="g1070">',
        '<rect x="214.80005" y="124.8" width="261.89999" height="109" id="rect898" fill="#9c27b0" />',
        '<rect x="496.70001" y="124.8" width="261.89999" height="109" id="rect900" fill="#e6e6e6" />',
        '<rect x="778.5" y="124.8" width="261.89999" height="109" id="rect902" fill="#e6e6e6" />',
        '<rect x="1060.4" y="124.8" width="261.89999" height="109" id="rect904" fill="#e6e6e6" />',
        '<rect x="1342.3" y="124.8" width="261.89999" height="109" id="rect906" fill="#e6e6e6" />',
        '<rect x="1624.1" y="124.8" width="261.89999" height="109" id="rect908" fill="#e6e6e6" />',
        '</g>',
        '<g id="g1062">',
        '<rect x="214.80005" y="1059" width="261.89999" height="109" id="rect912" fill="#9c27b0" />',
        '<rect x="496.70001" y="1059" width="261.89999" height="109" id="rect914" fill="#9c27b0" />',
        '<rect x="778.5" y="1059" width="261.89999" height="109" id="rect916" fill="#9c27b0" />',
        '<rect x="1060.4" y="1059" width="261.89999" height="109" id="rect918" fill="#9c27b0" />',
        '</g>',
        '<rect x="214.80005" y="357" width="1107.4" height="580" id="rect922" fill="#e6e6e6" />',
        '<g id="g1056">',
        '<rect x="214.80005" y="1317.4" width="1107.4" height="20.6" id="rect924" fill="#e6e6e6" />',
        '<rect x="214.80005" y="1387.7" width="1107.4" height="20.6" id="rect926" fill="#e6e6e6" />',
        '<rect x="214.80005" y="1247.1" width="1107.4" height="20.6" id="rect928" fill="#e6e6e6" />',
        '<rect x="214.80005" y="1458.1001" width="1107.4" height="20.6" id="rect930" fill="#e6e6e6" />',
        '<rect x="214.80005" y="1528.3999" width="1107.4" height="20.6" id="rect932" fill="#e6e6e6" />',
        '<rect x="214.80005" y="1598.7" width="1107.4" height="20.6" id="rect934" fill="#e6e6e6" />',
        '<rect x="214.80005" y="1669" width="1107.4" height="20.6" id="rect936" fill="#e6e6e6" />',
        '</g>',
        '<g id="g1047">',
        '<rect x="1445.4" y="357" width="440.60001" height="228.39999" id="rect940" fill="#e6e6e6" />',
        '<rect x="1445.4" y="615.40002" width="440.60001" height="20.6" id="rect942" fill="#e6e6e6" />',
        '</g>',
        '<g id="g1039">',
        '<rect x="1445.4" y="1059" width="440.60001" height="228.39999" id="rect946" fill="#e6e6e6" />',
        '<rect x="1445.4" y="1317.4" width="440.60001" height="20.6" id="rect948" fill="#e6e6e6" />',
        '</g>',
        '<g id="g1035">',
        '<rect x="1445.4" y="1410.6" width="440.60001" height="228.39999" id="rect952" fill="#e6e6e6" />',
        '<rect x="1445.4" y="1669" width="440.60001" height="20.6" id="rect954" fill="#e6e6e6" />',
        '</g>',
        '<g id="g1043">',
        '<rect x="1445.4" y="708.59998" width="440.60001" height="228.39999" id="rect958" fill="#e6e6e6" />',
        '<rect x="1445.4" y="967" width="440.60001" height="20.6" id="rect960" fill="#e6e6e6" />',
        '</g>',
        '</svg>',
        `<script src="${domain}/yupchat.js"></script>`,
        '<script>',
        'var widget = YupChat();',
        'widget.config({',
        'align: \'right\',',
        'color: \'#0E1421\',',
        'pushColor: \'#00cc00\',',
        'title: \'Yup Chat\',',
        'intro: \'Olá tudo bem? Para poder ajudar, qual seu nome completo?\'',
        '});',
        `widget.open('${this.accountId}');`,
        '</script>',
        '</body>',
        '</html>',
      ];
      return `data:text/html,${encodeURIComponent(html.join('\n'))}`;
    },
  },
  data() {
    return {
      queueOpenRooms: {},
      queueOpenTimeout: 0,
      id: null,
      token: null,
      // hasNotifications: false,
      status: 'OFFLINE',
      firstLaunch: false,
      ready: false,
      starting: false,
      myUserId: null,
      profiles: [],
      userDisconnect: false,
      usersQueue: 0,
      // queueDepartments: {},
      sumQueuesTimeout: 0,
      history: [],
      historyRefresh: 0,
      // historyRoomsId: [],
      pendings: [],

      toasts: [],
      toastDisconnect: null,

      hasQueue: -1,
      hasOnCall: 0,
      // hasAgentsOnCall: 0,
      hasHistory: -1,
      files: [],
      messages: [],
      profilePics: {},
      agents: [],
      departments: [],
      tabs: [],

      administer: [],
      administerUpdateTimeout: 0,

      agentsLoading: false,
      departmentsLoading: false,
      tabsLoading: false,
      loadingMessages: false,
      toggleLoaderTimeout: 0,

      accountTagsLoading: false,

      alreadyConnected: false,
      nextRequesting: false,
      nextTimeout: 0,

      associativeTranscriptsHistory: [],
      associativeTranscriptsTotal: {},
      associativeTranscriptsMessages: [],
      associativeTranscriptsPage: {},
      associativeTranscriptsNoMore: {},
      associativeTranscriptsLoading: {},
      associativeTranscriptsLoadingMessages: false,

      managedAgents: [],
      subscribeTimeouts: {},

      contextApp: null,
      volume: 1,
      page: 0,
      timeoutMgs: {},
      totalMessages: {},
      newMessages: {},
      historyData: {},
      lastAccountId: null,
      socket: null,
      stompClient: null,
      reconnecting: -1,
      errorConnection: false,
      callUpdateDataTimeout: 0,
      callUpdateManagedTimeout: 0,
      flagTimeouts: {},

      callUpdateCounterTimeout: {},

      queueCurrentDepartment: null,
      queueDepartments: {},
      queueTotal: -1,

      checkingConnection: false,

      statusTime: 0,
      statusTimeTimeout: 0,

      accountTags: [],
      accountController: null,

      sampleMessagesLoading: false,
      sampleMessages: [
        // { key: 'xxx', message: 'foo bar baz foo bar baz foo bar baz foo bar baz foo bar baz' },
        // { key: 'xxy', message: 'foo bar baz foo bar baz foo bar baz foo bar baz foo bar baz' },
        // { key: 'xxz', message: 'foo bar baz foo bar baz foo bar baz foo bar baz foo bar baz' },
        // { key: 'abc', message: 'foo bar baz foo bar baz foo bar baz foo bar baz foo bar baz' },
        // { key: 'axa', message: 'foo bar baz foo bar baz foo bar baz foo bar baz foo bar baz' },
        // { key: 'def', message: 'foo bar baz foo bar baz foo bar baz foo bar baz foo bar baz' },
      ],

      queuedEnabled: true,
      acceptanceOption: false,

      muteTime: 0,
      muteStatus: 'disabled',
      isMuted: false,

      titleTimeout: 0,

      // others
      whatsApp360: false,
    };
  },
  methods: {
    setup(context) {
      if (_launched) return;

      _launched = true;

      this.contextApp = context;

      console.log('userId', this.userId);

      if (this.userId) {
        const volume = parseInt(localStorage.getItem(`yup_chat_volume:${this.userId}`), 10);

        if (volume > 1 || Number.isNaN(volume)) {
          this.volume = 1;
        } else if (volume <= 0) {
          this.volume = 0;
        } else {
          this.volume = volume;
        }
      }

      this.preConnect();
      this.refreshMute();
    },
    acceptConnect() {
      const plan = this.contextApp?.$store?.state.plans.omniPlan;
      const account = this.contextApp?.$store?.state?.account;
      const role = account?.role;

      if (role) console.log('acceptConnect:role', role, this.contextApp.$store);

      return plan && (role === 'admin' || role === 'owner' || account?.['is_agent']);
      // || role === 'sales'
      // return this.contextApp.$store.state.account.is_agent;
    },
    async reconnect() {
      console.log('reconnect');
      await this.disconnect();
      this.preConnect();
    },
    preConnect() {
      const accountId = this.accountId;

      // console.log('preConnect', accountId);

      if (accountId) {
        // console.log('preConnect', 'AccountService.getInfo', accountId);
        this.lastAccountId = accountId;
        this.connect();
      } else {
        setTimeout(() => this.preConnect(), 500);
      }
    },
    async sync() {
      return webApi.get('webchat/sync');
    },
    async systemConfigs() {
      const response = await webApi.get('webchat/system');

      // console.info('my-listing system', response.data.live.queued_enabled);

      this.queuedEnabled = response.data.live.queued_enabled;

      if (!this.queuedEnabled) {
        this.acceptanceOption = response.data.live.acceptance_option === true;
      } else {
        this.acceptanceOption = false;
      }

      return response;
    },
    async connect() {
      // console.log('preConnect -> connect', 'this.ready:', this.ready, 'this.starting:', this.starting);

      if (this.ready || this.starting) return;

      this.starting = true;

      // console.log('CONECTAR', this.ready);

      try {
        const response = await this.sync();

        await this.delay(200);
        await this.systemConfigs();

        await this.delay(200);
        await this.requestAccountTags();

        await this.delay(200);
        await this.requestTabs();

        await this.delay(200);
        this.startSocket();
      } catch (ee) {
        this.contextApp.$toast.show({
          title: 'Chat auth',
          content: String(ee),
          type: 'danger',
        });

        this.starting = false;

        // throw new Error(String(ee));
      }
    },
    async disconnect(fromRequest = false) {
      // if (this.ready) {
      //   await this.emit('go-offline');
      //   await this.socket.disconnect(true);

      //   this.socket = null;
      //   this.page = 0;
      //   this.ready = false;
      //   this.online = false;
      // }

      // console.log('disconnect', this.ready);

      if (this.ready === false) return;

      const cStatus = this.status;

      this.status = '';

      this.callStatusTime(0, true);

      clearInterval(this.administerUpdateTimeout);

      // Encerra timeouts pendentes
      Object.values(this.flagTimeouts).forEach((timeout) => clearTimeout(timeout));
      Object.values(this.subscribeTimeouts).forEach((timeout) => clearTimeout(timeout));

      // Rejeitar transferencias pendentes
      this.toasts.forEach((toast) => {
        console.log('toast', toast);
        if (toast && toast.reject) toast.reject();
      });

      // console.log('toastDisconnect', this.toastDisconnect);

      if (this.toastDisconnect && this.toastDisconnect.reject) this.toastDisconnect.reject();

      console.log('disconnect1');

      // Remover
      this.history.forEach((room) => this.unsubscribeRoom(room));

      console.log('disconnect2');

      if (this.stompClient) {
        this.stompClient.disconnect(() => {
        });
        this.stompClient = null;
      }

      console.log('disconnect3', cStatus);

      if (!fromRequest) await this.setStatus(cStatus);

      this.ready = false;
      this.starting = false;
      this.myUserId = null;

      this.status = 'OFFLINE';
      this.history = [];
      // this.historyRoomsId = [];
      this.files = [];
      this.messages = [];
      this.agents = [];
      this.managedAgents = [];
      this.departments = [];
      this.agentsLoading = false;
      this.departmentsLoading = false;
      this.tabsLoading = false;
      this.timeoutMgs = {};
      this.totalMessages = {};
      this.newMessages = {};
      this.historyData = {};

      this.pendings = [];

      this.associativeTranscriptsHistory = [];
      this.associativeTranscriptsTotal = {};
      this.associativeTranscriptsPage = {};
      this.associativeTranscriptsNoMore = {};
      this.associativeTranscriptsLoading = {};

      this.flagTimeouts = {};
      this.subscribeTimeouts = {};

      this.hasQueue = -1;
      this.hasOnCall = 0;
      this.hasHistory = -1;

      this.queueCurrentDepartment = null;
      this.queueDepartments = {};
      this.queueTotal = -1;
    },
    removeStompRooms() {
      this.history.forEach((room) => {
        room.stomp = null;
      });
    },
    callStatusTime(time, stop = false) {
      clearInterval(this.statusTimeTimeout);

      if (stop === true) return;

      this.statusTime = time === 0 ? 1 : (new Date() - new Date(time)) / 1000;

      console.log('this.statusTime', this.statusTime, time);

      this.statusTimeTimeout = setInterval(() => {
        this.statusTime += 1;
      }, 1000);
    },
    async setStatus(status, persists = true) {
      console.log('setStatus', '\n  ready:', this.ready, '\n  userId:', this.userId, '\n  status:', status, '\n  this.status:', this.status);

      if (this.ready && this.userId && status !== this.status) {
        this.status = status;

        if (persists === true) {
          try {
            localStorage.setItem(`live_status:${this.userId}`, status);
            await webApi.put('webchat/status', { status });
          } catch (ee) {
            // this.contextApp.$toast.show({
            //   title: 'Chat auth',
            //   content: String(ee),
            //   type: 'danger',
            // });
            console.error(ee);
          }

          this.callStatusTime(0, false);
        }
        // else {
        //   this.callStatusTime(0, true);
        // }
      }

      // if (!status) {
      //   this.userDisconnect = true;

      //   if (this.stompClient) {
      //     this.stompClient.disconnect(() => {});
      //     this.stompClient = null;
      //   }

      //   this.removeStompRooms();

      //   console.log('DESCONECTOU');

      //   this.socket = null;
      //   this.ready = false;
      // } else if (status && !this.stompClient) {
      //   this.connect();
      // }
    },
    startSocket() {
      console.log('startSocket');

      const state = this.contextApp?.$store?.state;
      const user = state?.auth?.user?.email;
      const invalid = typeof state?.account?.['is_agent'] === 'undefined';

      if (invalid) {
        this.contextApp?.$store?.dispatch('account/info');
      }

      if (!invalid && user) {
        this.startSocketNext(user);
      } else {
        setTimeout(() => {
          this.startSocket();
        }, 500);
      }
    },
    startSocketNext(user) {
      if (!this.acceptConnect()) {
        console.log('acceptConnect:false', 'startSocketNext');
        this.ready = false;
        this.reconnecting = -1;
        this.errorConnection = false;
        this.starting = false;
        return;
      }

      console.log('acceptConnect:true', 'startSocketNext');

      const accountId = this.accountId;

      console.log('startSocket:data', this.contextApp.$store.state);

      const authHeaders = {
        account_id: accountId,
        username: user,
        channel: 'web-api',
      };

      // const socket = new SockJS('https://dev.yup.chat/ws/chat', null, {});
      this.socket = new SockJS(LIVE_WEBSOCKET, null, {});

      this.stompClient = Stomp.over(this.socket, {
        protocols: Stomp.VERSIONS.supportedProtocols(),
        debug: true,
      });

      this.myUserId = null;

      this.stompClient.connect(authHeaders, (frame) => {
        const status = localStorage.getItem(`live_status:${this.userId}`) || 'AVAILABLE';
        console.log('startSocketNext::connect', status, this.userId);
        this.status = status;
        this.ready = true;
        this.reconnecting = -1;
        this.errorConnection = false;
        this.starting = false;

        // console.log('stompClient:connect', frame);

        // if (!this.myUserId) this.restoreSubscribes();

        this.stompClient.subscribe(`/topic/${user};${accountId}/messages`, (messageOutput) => {
          this.manageMain(messageOutput);
          this.checkConnection();
        });

        console.log('this.firstLaunch', this.firstLaunch);

        this.status = '';
        this.setStatus(status, this.firstLaunch); // Não persiste

        this.firstLaunch = false;
      }, async (error) => {
        // console.error('stompClient:connect', error);

        if (error.command === 'ERROR' && error.headers.message.indexOf('already connected') !== -1) {
          this.alreadyConnected = true;

          if (this.stompClient) {
            this.stompClient.disconnect(() => { });
          }

          this.stompClient = null;

          if (this.socket) this.socket.close();

          this.socket = null;

          this.disconnect();

          this.toastDisconnect = this.$toast.show({
            id: 'live',
            title: 'Conexão',
            type: 'danger',
            content: 'Seu "Live" está conectado em outro dispositivo, gostaria de tentar reconectar aqui?',
            requireInteraction: (accepted) => {
              this.toastDisconnect = null;
              if (accepted) this.disconnectOthers();
            },
          });
        } else if (!this.userDisconnect && error.code >= 1000 && error.code <= 1015) {
          this.reconnectAfter(10);
          this.errorConnection = true;
        }
      });

      // this.stompClient.disConnect();
    },
    administerUpdate() {
      const administer = this.administer;

      this.administerUpdateTimeout = setInterval(() => {
        administer.forEach((entry) => {
          if ('maximum_wait' in entry && entry.queue > 0) {
            // console.log('maximum_wait::before', entry.maximum_wait);

            entry.maximum_wait += 0.01;

            if (entry.maximum_wait % 1 >= 0.6) {
              entry.maximum_wait = Math.round(entry.maximum_wait);
            }

            // console.log('maximum_wait::after', entry.maximum_wait);
          }
        });
      }, 1000);
    },
    async disconnectOthers() {
      try {
        await webApi.post('webchat/disconnect');
        this.alreadyConnected = false;
        this.stompClient = null;
        this.socket = null;
        this.startSocket();
      } catch (ee) {
        this.contextApp.$toast.show({
          title: 'Chat auth',
          content: String(ee),
          type: 'danger',
        });

        this.alreadyConnected = false;
        this.reconnectAfter(10);
      }
    },
    async reconnectAfter(sec) {
      this.reconnecting = sec;

      if (sec === 5) this.socket = null;

      if (sec === 0) {
        if (this.stompClient) {
          this.stompClient.disconnect(() => { });
        }

        if (this.socket) this.socket.close();

        setTimeout(() => {
          this.stompClient = null;
          this.socket = null;
          this.startSocket();
        }, 100);
      } else {
        sec -= 1;
        setTimeout(() => {
          this.reconnectAfter(sec);
        }, 1000);
      }
    },
    checkConnection() {
      if (this.checkingConnection || this.userDisconnect) return;

      this.checkingConnection = true;

      const interval = setInterval(() => {
        const store = this.contextApp.$store;
        const path = this.contextApp.$router.history.current.path;
        const logged = store.state?.auth?.token;

        // console.log('checkConnection:logged #1', logged);

        // if (this.userDisconnect) return;

        // desconecta do live se tiver feito logoff
        if (path.indexOf('/login') === 0 || !logged) {
          // console.log('checkConnection:disconnect');
          this.disconnect();
          // clearInterval(interval);
        } else if (logged) {
          // const isAgent = store.state.account.is_agent;

          // console.log('checkConnection:logged #2', {
          //   acceptConnect: this.acceptConnect(),
          //   currentAccountId: store.state.account.id,
          //   lastAccountId: this.lastAccountId,
          //   'this.accountId': this.accountId,
          // });

          if (this.accountId !== this.lastAccountId) {
            // Reconecta (quando necessário) se mudar a conta atual
            console.log('checkConnection#1');
            this.reconnect();
          }

          // else if (this.acceptConnect()) {
          //   console.log('checkConnection#2');
          //   this.preConnect();
          // }
        }
        // else {
        //   // console.log('checkConnection#3', store);
        // }
      }, 2000);
    },
    transfered(newRoom) {
      const roomId = newRoom.room_id;
      const hasPengings = this.pendings.some((room) => room.room_id === roomId);

      if (!hasPengings) {
        this.pendings.push(newRoom);
        // this.contextApp.$root.$emit('live:transfer', newRoom);

        const message = `O cliente ${newRoom.name} (${newRoom.email ?? newRoom.phone}) foi transferido para você, deseja aceitar?`;

        this.toasts.push(this.$toast.show({
          title: 'Transferencia',
          content: message,
          type: 'success',
          requireInteraction: (accepted) => {
            if (accepted) {
              this.transferAcceptOrReject(roomId, true);
            } else {
              this.transferAcceptOrReject(roomId, false);
            }
          },
        }));
      }
    },
    toggleLoader(toggle, timeout) {
      if (this.toggleLoaderTimeout) clearTimeout(this.toggleLoaderTimeout);

      if (toggle === this.loadingMessages) return;

      if (!timeout) {
        this.loadingMessages = toggle;
      } else {
        this.toggleLoaderTimeout = setTimeout(() => {
          this.loadingMessages = toggle;
        }, timeout);
      }
    },
    transferAcceptOrReject(roomId, accept) {
      const index = this.pendings.findIndex((room) => room.room_id === roomId);

      if (index === -1) return;

      const user = this.contextApp.$store.state.auth.user.email;
      const accountId = this.contextApp.$store.state.account.id;

      this.stompClient.send('/app/chat', JSON.stringify({
        message: roomId,
        event: accept ? 'transfer_accept' : 'transfer_refused',
      }), { room: `${user};${accountId}` });

      const entry = this.pendings.splice(index, 1)[0];

      console.log('transferAcceptOrReject::accept', accept, entry);

      if (accept) {
        const room = this.putRoom(entry, 'me');

        Vue.set(room, 'type', 'me');
        Vue.set(room, 'localStatus', '');
        Vue.set(room, 'status', entry.status);

        console.log('transferAcceptOrReject::room', room);
        this.contextApp.$router.push('/live');
        // this.contextApp.$router.push({
        //   path: '/live',
        //   params: {
        //     currentRoom: room,
        //   },
        // });
      }

      this.callUpdateData();
    },
    putManagedAgent(agent, rooms) {
      const exists = this.managedAgents.find((entry) => entry.id === agent.id);
      const lastUpdate = agent.status_date ?? agent.creation_date;

      console.log('putManagedAgent', rooms, agent);

      let status = null;

      status = !agent.active ? 'OFFLINE' : agent.status;

      if (exists) {
        Vue.set(exists, 'status', status);
        Vue.set(exists, 'rooms', rooms);
        Vue.set(exists, 'lastUpdate', lastUpdate);
        Vue.set(exists, 'name', agent.name);
        Vue.set(exists, 'phone', agent.phone);
        Vue.set(exists, 'email', agent.email);
      } else {
        this.managedAgents.push({
          status,
          rooms,
          lastUpdate,
          id: agent.id,
          name: agent.name,
          phone: agent.phone,
          email: agent.email,
        });

        // const statusTypes = [
        //   'OFFLINE',
        //   'BREAK',
        //   'LONG_BREAK',
        //   'SHORT_BREAK',
        //   'UNAVAILABLE',
        //   'AVAILABLE',
        // ];

        // for (let i = 0; i < 36; i += 1) {
        //   this.managedAgents.push({
        //     rooms,
        //     lastUpdate,
        //     id: `${i % 6}:${statusTypes[i % 6]}:${Math.random() * 100}:${Date.now()}`,
        //     name: agent.name,
        //     phone: agent.phone,
        //     email: agent.email,
        //     status: statusTypes[i % 6],
        //   });
        // }
      }
    },
    getMessageType(attachments) {
      /*
      {
          "deleted": null,
          "from": null,
          "message": null,
          "id": null,
          "status": null,
          "event": null,
          "attachments": null,
          "creation_date": null,
          "last_update": null,
          "room_id": null,
          "user_id": null,
          "type_user": null,
          "profile_pic_user": null,
          "file_size_bytes": 0,
          "channel_id": null,
          "id_external": null,
          "response_to": null
      }
      */
      if (attachments?.length > 0) {
        const attachment = attachments[0];
        const mediaType = attachment.media_type || '';
        const uri = attachment.uri?.toLowerCase();
        const type = attachment.type?.toLowerCase();

        console.log('getMessageType', {
          attachment, mediaType, uri, type,
        });

        // console.log('appendMessage::files.push()', { type, msgId, attachment });

        if (type !== 'audio' && type !== 'image' && type !== 'pdf' && type !== 'video') {
          if (mediaType.indexOf('audio/') === 0 || audioExtensions.test(uri)) return 'audio';

          if (mediaType.indexOf('video/') === 0 || videoExtensions.test(uri)) return 'video';

          if (mediaType.indexOf('image/') === 0 || imageExtensions.test(uri)) return 'image';

          if (mediaType.indexOf('/pdf') !== -1 || pdfExtensions.test(uri)) return 'pdf';
        } else {
          return type;
        }
      }

      return null;
    },
    putRoom(room, type, spyMode = false) {
      const fromOffline = type === 'client_off';

      let roomId = fromOffline ? room.client_off_id : room.room_id;

      if (spyMode) roomId = `spy:${roomId}`;

      const history = this.history;
      const clientId = fromOffline ? room.client_off_id : room.client_id;
      const agentId = room.agent_id;
      const exists = history.find((r) => r.roomId === roomId);
      const channel = fromOffline ? room.from : room.channel;

      const accountExternalId = room.account_external_identification;
      const clientExternalId = room.client_external_id;

      let tags = [];

      if (Array.isArray(room.tags)) {
        tags = room.tags.map((tag) => tag?.name).filter((tag) => typeof tag === 'string' && tag !== '');
      }

      console.log(`putRoom:${type}`, !!exists, room);

      const origin = room.account_channel_name_identification;

      let lastMessageType = this.getMessageType(room.last_message?.attachments ?? []);
      let lastMessage = room.last_message?.message || '';
      let sessionTime = new Date();
      let lastMessageSentAt = room.last_message_sent || room.creation_date;

      if (lastMessage) lastMessage = lastMessage.trim().replace(this.accountToGeneral, 'Geral');

      if (room.session_minutes) sessionTime -= (room.session_minutes * 1000 * 60);

      if (fromOffline && room.messages.length) {
        const lm = room.messages[room.messages.length - 1];

        if (lm) {
          lastMessageType = lm.uri ? this.getMessageType([{ uri: lm.uri, type: lm.type }]) : null;
          lastMessage = lm.message || '';

          if (lm.creation_date) lastMessageSentAt = lm.creation_date;
        }
      }

      // Quando estiver em LOCK a sala nao sera atualizada, pois provavelmente esta esperando o fechamento
      if (exists?.lock === true) return exists;

      const firstName = room?.contact?.['first_name'] || '';
      const lastName = room?.contact?.['last_name'] || '';
      const username = `${firstName} ${lastName}`.trim();

      if (exists) {
        if (type !== 'agent-manager') exists.type = type;

        exists.fromIdle = type === 'idle';
        exists.fromOffline = fromOffline;

        exists.agentId = agentId;
        exists.lastMessage = lastMessage;
        exists.lastMessageType = lastMessageType;
        exists.tags = tags;
        exists.originalTags = [].concat(tags);
        exists.status = room.status;
        exists.channel = channel;

        if (username) exists.username = username;

        exists.tabId = room.tabulation_id;
        exists.lastMessageSentAt = lastMessageSentAt;
        exists.session = room.session;
        exists.sessionMinutes = room.session_minutes;
        exists.sessionTime = sessionTime;
        exists.origin = origin;
        exists.phone = room.phone;
        exists.email = room.email;

        exists.contact.id = room?.contact?.id;
        exists.contact.gender = room?.contact?.gender;
        exists.contact.city = room?.contact?.city;
        exists.contact.country = room?.contact?.country;
        exists.contact.description = room?.contact?.description;
        exists.contact.company = room?.contact?.company;
        exists.contact.address = room?.contact?.address;
        exists.contact.number = room?.contact?.number;
        exists.contact.complement = room?.contact?.complement;
        exists.contact.district = room?.contact?.district;
        exists.contact.zip = room?.contact?.zip;
        exists.contact.state = room?.contact?.state;
        exists.contact.firstName = room?.contact?.['first_name'];
        exists.contact.lastName = room?.contact?.['last_name'];
        exists.contact.birthDate = room?.contact?.['birth_date'];
        exists.contact.phone = room?.contact?.phone;
        exists.contact.mobileNumber = room?.contact?.['mobile_number'];
        exists.contact.email = room?.contact?.email;

        exists.accountExternalId = accountExternalId;
        exists.clientExternalId = clientExternalId;

        // if (agentId !== this.myUserId && !spyMode) {
        //   setTimeout(() => {
        //     this.flagAsClosed(exists);
        //   }, 1);
        // }

        return exists;
      }

      if (type === 'my-listing') this.notify(true);

      // this.historyRoomsId.push(roomId);

      let email = room?.contact?.email;

      if (!email || email.indexOf('@') === -1) email = '';

      const newRoom = {
        lock: false,
        type,
        roomId,
        agentId,
        lastMessage,
        lastMessageType,
        tags,
        fromIdle: type === 'idle',
        fromOffline,
        originalTags: [].concat(tags),
        status: room.status,
        localStatus: '',
        parentRoomId: [],
        clientId,
        channel,
        username,
        protocol: room.protocol,
        createdAt: room.creation_date ?? room.open_date,
        closedAt: room.close_date,
        tabId: room.tabulation_id,
        requireTab: false,
        lastMessageSentAt,
        session: room.session,
        sessionMinutes: room.session_minutes,
        sessionTime,
        stomp: null,
        origin,
        solved: false,
        phone: room.phone,
        email: room.email,
        clientExternalId,
        accountExternalId,
        contact: {
          id: room?.contact?.id,
          gender: room?.contact?.gender,
          city: room?.contact?.city,
          country: room?.contact?.country,
          description: room?.contact?.description,
          company: room?.contact?.company,
          address: room?.contact?.address,
          number: room?.contact?.number,
          complement: room?.contact?.complement,
          district: room?.contact?.district,
          zip: room?.contact?.zip,
          state: room?.contact?.state,
          firstName: room?.contact?.['first_name'],
          lastName: room?.contact?.['last_name'],
          birthDate: room?.contact?.['birth_date'],
          phone: room?.contact?.phone,
          mobileNumber: room?.contact?.['mobile_number'],
          email,
        },
      };

      history.unshift(newRoom);

      this.historyRefresh += 1;

      if (!spyMode && room.messages?.length) {
        setTimeout(() => {
          this.appendOfflineMessages(newRoom, room.messages);
        }, 1);
      }

      return newRoom;
    },
    callUpdateCounter(roomId) {
      clearTimeout(this.callUpdateCounterTimeout[roomId]);

      this.callUpdateCounterTimeout[roomId] = setTimeout(() => {
        this.updateCounter(roomId);
      }, 250);
    },
    updateCounter(roomId) {
      let total = 0;
      let news = 0;

      this.messages.forEach((message) => {
        // mensagens "virtuais" não serão contabilizadas
        if (!message.id || message.roomId !== roomId) return;

        total += 1;

        if (message.me === false && message.status !== 'READ') {
          // console.log('[flagMessage]#1', `flag:${flag}, me:${message.me}, currentStatus:${status}`);
          news += 1;
        }
      });

      console.log('flagMessages', { total, news });

      Vue.set(this.totalMessages, roomId, total);
      Vue.set(this.newMessages, roomId, news);
    },
    refreshTitle() {
      console.log('refreshTitle#1');
      clearTimeout(this.titleTimeout);
      this.titleTimeout = setTimeout(() => this.setRefreshTitle(), 500);
    },
    setRefreshTitle() {
      console.log('refreshTitle#2');

      const originalTitle = document.title.replace(/^\((\d+?)\)/, '');

      let total = 0;

      if (this.queueTotal > 0) total += this.queueTotal;

      if (this.unread > 0) total += this.unread;

      const strNotify = total ? `(${total}) ` : '';

      document.title = `${strNotify}${originalTitle}`;
    },
    callUpdateData() {
      clearTimeout(this.callUpdateDataTimeout);

      this.callUpdateDataTimeout = setTimeout(() => {
        this.updateData();
      }, 250);
    },
    updateData() {
      // console.log('[this.history]', JSON.parse(JSON.stringify(this.history)));

      this.history = this.history.sort((a, b) => {
        const result = new Date(a.lastMessageSentAt) - new Date(b.lastMessageSentAt);

        if (result < 0) return 1;

        if (result > 0) return -1;

        return 0;
      });

      // let hasQueue = 0;
      let hasOnCall = 0;
      let hasHistory = 0;
      // let hasAgentsOnCall = 0;

      const total = this.history.length;

      for (let i = 0; i < total; i += 1) {
        const room = this.history[i];
        switch (room.type) {
          // case 'queue':
          //   hasQueue += 1;
          //   break;
          case 'me':
            hasOnCall += 1;
            // console.log('[updateData]', room.type, room);

            if (room.status !== 'CLOSED' && room.agent_id === this.myUserId) {
              this.open(room, 'updateData', true);
            }
            break;
          case 'my-listing':
          case 'agent-manager':
          case 'client_off':
          case 'idle':
            // hasAgentsOnCall += 1;
            console.log('[no updateData]', room.type);
            break;
          case 'closed':
            hasHistory += 1;
            break;
          default:
            console.error('Invalid entry', room);
        }
      }

      // this.hasQueue = hasQueue;
      this.hasOnCall = hasOnCall;
      // this.hasAgentsOnCall = hasAgentsOnCall;
      this.hasHistory = hasHistory;

      // console.log('this.hasQueue', hasQueue);
      // console.log('this.hasOnCall', hasOnCall);
      // console.log('this.hasHistory', hasHistory);

      this.historyRefresh += 1;

      console.log('this.historyRefresh:liveserver', this.$live.historyRefresh);
    },
    callUpdateManaged() {
      clearTimeout(this.callUpdateManagedTimeout);

      this.callUpdateManagedTimeout = setTimeout(() => {
        this.updateManaged();
      }, 250);
    },
    updateManaged() {
      // console.log('[this.history]', JSON.parse(JSON.stringify(this.history)));

      const statusTypes = {
        AVAILABLE: 5,
        UNAVAILABLE: 4,
        SHORT_BREAK: 3,
        BREAK: 2,
        LONG_BREAK: 1,
        OFFLINE: 0,
      };

      this.managedAgents = this.managedAgents.sort((a, b) => {
        if (a.status !== null && b.status !== null) {
          const statusA = statusTypes[a.status];
          const statusB = statusTypes[b.status];

          if (statusA > statusB) return -1;

          if (statusA < statusB) return 1;
        } else if (a.status === null) {
          if (b.status !== null) return 1;
        } else if (b.status === null) {
          return -1;
        }

        const compare = a.name.localeCompare(b.name, 'pt', { ignorePunctuation: true });

        if (compare < 0) return -1;

        if (compare > 0) return 1;

        return 0;
      });

      this.contextApp.$root.$emit('live:agents', this.managedAgents.map((agent) => agent.id));
    },
    // sortUserHistory(history) {
    //   history = history.sort((a, b) => {
    //     const result = new Date(a.lastMessageSentAt) - new Date(b.lastMessageSentAt);

    //     if (result < 0) return 1;

    //     if (result > 0) return -1;

    //     return 0;
    //   });
    // },
    sumQueues() {
      let sum = 0;

      Object.keys(this.queueDepartments).forEach((queue) => {
        sum += this.queueDepartments[queue];
      });

      this.usersQueue = sum;
    },
    // checkQueue(room, target) {
    //   const index = this.history.findIndex((historyRoom) => historyRoom.roomId === room.roomId);

    //   // console.log('checkQueue', target, room);

    //   if (!room.status) room.status = 'open';

    //   if (index !== -1) {
    //     const current = this.history[index];

    //     Object.keys(room).forEach((key) => {
    //       if (
    //         key === 'channel' ||
    //         key === 'status' ||
    //         key === 'protocol' ||
    //         key === 'lastMessage' ||
    //         key === 'lastMessageSentAt'
    //       ) {
    //         current[key] = room[key];
    //       }
    //     });
    //   } else {
    //     room.news = 0;
    //     room.total = 0;

    //     if (target === 'history' && room.status === 'open') {
    //       setTimeout(() => {
    //         this.emit('rejoin', { roomId: room.roomId });
    //       }, 10);
    //     }

    //     this.updateFromHistory(room);

    //     this.history.push(room);
    //   }

    //   if (target === 'transferred') {
    //     // Force open
    //     room.status = 'open';

    //     setTimeout(() => {
    //       this.requestMessage(room.roomId, 'transferred');
    //     }, 10);

    //     this.notify(true);
    //   }

    //   // Force refresh status
    //   Vue.set(room, 'status', room.status);

    //   // console.log('--------------------------');
    //   // console.log('checkQueue:target', target);
    //   // console.log('checkQueue:index', room.roomId, { index });
    //   // console.log('room', JSON.parse(JSON.stringify(room)));
    //   // console.log('this.history', JSON.parse(JSON.stringify(this.history)));
    //   // console.log('==========================');
    // },
    sortMainHistory() {
      const total = this.history.length;

      if (total > 1) {
        this.history = this.history.sort((a, b) => {
          const result = new Date(a.lastMessageSentAt) - new Date(b.lastMessageSentAt);

          if (result < 0) return 1;

          if (result > 0) return -1;

          return 0;
        });

        this.updateTotal();
      } else if (total === 1 && this.history[0]) {
        let opened = 0;
        let closed = 0;

        if (this.history[0].status === 'open') {
          opened = 1;
        } else {
          closed = 1;
        }

        this.hasQueue = opened;
        this.hasHistory = closed;
      } else {
        this.hasQueue = 0;
        this.hasHistory = 0;
      }
    },
    generateMessage(roomId, room, entry) {
      if (entry.uri && !entry.attachments) {
        entry.attachments = [{ type: entry.type, uri: entry.uri }];
      }

      // entry.attachments = entry?.attachments?.filter((file) => file?.uri?.indexOf('http') === 0);

      if (entry.attachments && entry.attachments.length) {
        /*
        const fromEmail = entry.attachments.find((item) => (
          item.source && item.content_transfer_enconding === 'quoted-printable'
        ));

        console.info('fromEmail', { fromEmail });

        if (fromEmail.source) message = QuotedPrintable(fromEmail.source);
        */

        for (let i = entry.attachments.length - 1; i >= 0; i -= 1) {
          const attachment = entry.attachments[i];
          const mediaType = attachment.media_type || '';

          let uri = attachment?.uri;
          let type = attachment.type?.toLowerCase();

          if (uri) {
            const curi = uri?.toLowerCase();

            console.log('appendMessage::files.push()', { roomId, type, attachment });

            // if (type !== 'audio' && type !== 'image' && type !== 'pdf' && type !== 'video') {
            if (mediaType.indexOf('audio/') === 0 || audioExtensions.test(curi)) {
              type = 'audio';
            } else if (mediaType.indexOf('video/') === 0 || videoExtensions.test(curi)) {
              type = 'video';
            } else if (mediaType.indexOf('image/') === 0 || imageExtensions.test(curi)) {
              type = 'image';
            } else if (mediaType.indexOf('/pdf') !== -1 || pdfExtensions.test(curi)) {
              type = 'pdf';
            }
            // }

            attachment.type = type;

            if (uri.indexOf('https://') === -1 && uri.indexOf('http://') === -1) {
              attachment.uri = `${LIVE_PUBLIC}/${attachment.uri.replace(/\\+/g, '/')}`;
            }
          } else if (attachment.source) {
            uri = attachment.source;
          }

          if (uri) this.files.push({ roomId, type, uri });
        }
      }

      const sentAt = entry.creation_date ? new Date(entry.creation_date) : entry.creation_date;
      const event = entry.event;
      const picture = entry.profile_pic_user;
      // const picture = 'https://avatarfiles.alphacoders.com/883/thumb-1920-88384.jpg';
      const automatic = event === 'greeting' ||
        event === 'transfer_agent' ||
        event === 'transfer_department' ||
        entry.type_user === 'BOT';

      let message = (entry.message ?? '').trim();

      if (picture) this.profilePics[roomId] = picture;

      const id = entry.id ?? entry.id_external;
      const me = entry.user_id === this.myUserId;

      if (message) message = message.replace(this.accountToGeneral, 'Geral');

      room.lastMessage = message;
      room.lastMessageSentAt = sentAt;
      room.lastMessageType = this.getMessageType(entry.attachments);

      return {
        id,
        roomId,
        message,
        picture,
        automatic,
        username: entry.from,
        sentAt,
        userId: entry.user_id,
        me,
        attachments: entry.attachments ?? [],
        status: entry.status,
        typeUser: entry.type_user,
        offline: entry.place === 'OFF',
      };
    },
    appendMessage(room, entry, fromOffline = false) {
      const msgId = entry.id ?? entry.id_external;
      const roomId = room.roomId;
      const message = this.messages.find((msg) => msg.id === msgId);

      if (!entry.id) entry.id = msgId;

      if (message) {
        Vue.set(message, 'status', entry.status);
        return;
      }

      const structure = this.generateMessage(roomId, room, entry);

      // if (fromOffline) console.log('appendMessage', { entry, structure });

      // setTimeout(() => {
      //   Vue.set(structure, 'status', 'DELETED');
      // }, 5000);

      this.messages.push(structure);

      if (fromOffline) return;

      if (entry.user_id !== this.myUserId) {
        // console.log('$$$messages', this.messages);

        if (!this.totalMessages[roomId]) {
          this.totalMessages[roomId] = 1;
        } else {
          this.totalMessages[roomId] += 1;
        }
      }

      this.trigger('$scroll', roomId);
      this.reqFlagMessages('DELIVERED', room);
      this.callUpdateData();
    },
    appendOfflineMessages(room, messages) {
      const roomId = room.roomId;

      console.log('appendOfflineMessages', roomId, room);

      messages.forEach((entry, index) => {
        entry.roomId = roomId;
        entry.id = `${roomId}:${index}`;
        this.appendMessage(room, entry, true);
      });

      this.trigger('$scroll', roomId);
    },
    updateMessage(evt, room, update) {
      const message = this.messages.find((msg) => msg.id === update.id);

      if (!message) return;

      if (message.status !== 'READ') {
        const status = evt.toUpperCase();
        message.status = status;
        Vue.set(message, 'status', status);
      }

      this.callUpdateData();
      this.callUpdateCounter(room.roomId);
    },
    reqFlagMessages(flag, room) {
      // if (room.type !== 'queue' && room.type !== 'me') return;

      const noUpdate = room.type !== 'queue' && room.type !== 'me';
      const roomId = room.roomId;

      console.log(`reqFlagMessages(${flag}, ${roomId})`, room.type);

      if (this.flagTimeouts[roomId]) clearTimeout(this.flagTimeouts[roomId]);

      this.flagTimeouts[roomId] = setTimeout(() => {
        this.flagMessages(flag, roomId, noUpdate);
      }, 500);

      // if (flag === 'READ') this.notify(false);
    },
    flagMessages(flag, roomId, noUpdate) {
      console.log('flagMessages:', flag, roomId, noUpdate);

      clearTimeout(this.flagTimeout);

      // this.totalMessages[roomId]

      let total = 0;
      let news = 0;

      this.messages.forEach((message) => {
        // mensagens "virtuais" não serão contabilizadas
        if (!message.id || message.roomId !== roomId) return;

        total += 1;

        const status = message.status;

        if (message.me === false && flag !== 'READ' && status !== 'READ') {
          // console.log('[flagMessage]#1', `flag:${flag}, me:${message.me}, currentStatus:${status}`);
          news += 1;
        }

        if (
          !noUpdate &&
          message.me === false &&
          status !== flag &&
          status !== 'READ' &&
          status !== 'FAIL' &&
          status !== 'DELETED'
        ) {
          this.flagMessage(message, flag, message.roomId);
        }
      });

      console.log('flagMessages', { total, news });

      Vue.set(this.totalMessages, roomId, total);
      Vue.set(this.newMessages, roomId, news);

      if (news) this.notify(true);
    },
    flagMessage(message, flag, roomId) {
      message.status = flag;

      Vue.set(message, 'status', flag);

      // console.log('[flagMessage]#2', message);

      this.stompClient.send('/app/chat', JSON.stringify({
        id: message.id,
        event: flag.toLowerCase(),
      }), { room: roomId });
    },
    validateFile(file, channel) {
      const size = file.size;
      const type = file.type;

      console.log('validateFile', { size, type, channel });

      if (channel === 'google-business') {
        return size <= 5000000;
        // return size <= 5242880;
      }

      if (channel.indexOf('whatsapp') === 0) {
        if (type.indexOf('video/') === 0) {
          return size <= 16777216; // limite 16 (MB)
        }
        if (type.indexOf('image/') === 0) {
          return size <= 14680064;
        }
        if (type.indexOf('application/') === 0 || type.indexOf('text/') === 0) {
          return size <= 104857600;
        }
      }

      if (channel.indexOf('rcs') === 0) {
        return size <= 5242880 && (
          type.indexOf('video/mp4') === 0 ||
          type.indexOf('video/webm') === 0 ||
          type.indexOf('image/jpeg') === 0 ||
          type.indexOf('image/gif') === 0 ||
          type.indexOf('image/png') === 0 ||
          type.indexOf('image/webp') === 0 ||
          type.indexOf('image/svg') === 0
        );
      }

      if (channel === 'messenger' || channel === 'instagram') {
        return size <= 23068672;
      }

      return size <= 14680064;
    },
    async imageToJpeg(url) {
      return new Promise((resolve, reject) => {
        console.log('imageToJpeg', url);
        const img = new Image();
        img.onload = () => {
          console.info('imageToJpeg', 'onload');

          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = this.width;
          canvas.height = this.height;

          canvas.height = img.naturalHeight;
          canvas.width = img.naturalWidth;

          // document.body.appendChild(img);
          // document.body.appendChild(canvas);

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          canvas.toBlob((blob) => {
            console.info('imageToJpeg', 'toBlob', blob);

            resolve(blob);
          }, 'image/jpeg', 0.8);
        };
        img.onerror = (a) => {
          console.error('imageToJpeg', a);
          resolve(null);
        };
        img.src = url;
      });
    },
    async sendFile(roomId, file, fileName, convert, message, channel = null) {
      const form = new FormData();

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      form.append('id_user', this.myUserId);
      form.append('id_room', roomId);
      form.append('message', message || ' ');

      if (fileName) {
        form.append('file', file, fileName);
      } else {
        form.append('file', file);
      }

      let req;

      if (convert) {
        form.append('channel', channel);

        return webApi.post('webchat/upload', form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }

      return webchatRequest.post('upload', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    sendTemplate(payload) {
      return webchatRequest.post('active-message/whatsapp-java', payload);
    },
    markAsRead(roomId) {
      const room = this.roomInfo(roomId);

      // console.log('#1', { room }, room.news);

      if (room) Vue.set(room, 'news', 0);

      // console.log('#2', { room }, room.news);

      this.notify(false);
    },
    markAsSolved(room) {
      if (room.fromOffline) {
        const clientId = room.clientId;

        room.solved = true;

        Vue.set(room, 'solved', true);

        this.history = this.history.filter((entry) => entry.clientId !== clientId);

        Vue.set(this, 'history', this.history);

        webApi.post(`webchat/solve/${clientId}`, {}).then(() => {
          setTimeout(() => this.requestTranscripts('offline', null), 500);
        });
      }
    },
    // removeAssociativeRoom(room) {
    //   const roomId = room?.roomId;

    //   if (!roomId) return;

    //   this.associativeTranscriptsHistory = this.associativeTranscriptsHistory.filter((r) => r.roomId !== roomId);
    // },
    updateFromHistory(room) {
      let username;

      if (room.contact && room.contact.first_name) {
        username = `${room.contact.first_name} ${room.contact.last_name || ''}`;
      } else if (room.from && room.from.username) {
        username = room.from.username;
      }

      if (room.room) {
        if (room.room.channel) {
          room.channel = room.room.channel;
        }

        if (room.room.protocol) {
          room.protocol = room.room.protocol;
        }

        if (room.room.status) {
          room.status = room.room.status;
        }

        if (room.room.lastMessage) {
          room.lastMessage = room.room.lastMessage;
        }

        if (room.room.lastMessageSentAt) {
          room.lastMessageSentAt = room.room.lastMessageSentAt;
        }
      }

      // console.log('room.contact', room.contact);

      if (room.contact) {
        if (room.contact.mobile_number) room.mobileNumber = room.contact.mobile_number;

        if (room.contact.email) room.email = room.contact.email;
      }

      if (room.mobileNumber) {
        room.mobileNumber = this.maskPhone(room.mobileNumber);
      } else {
        room.mobileNumber = null;
      }

      if (!room.email) room.email = null;

      if (username && username.trim() && username !== room.username) {
        Vue.set(room, 'username', username);
      }

      // console.log('updateFromHistory$1', { username });
      // console.log('updateFromHistory$2', { room });
    },
    updateFromMessage(message) {
      const room = this.roomInfo(message.roomId);

      if (room) {
        if (message.sentAt && new Date(message.sentAt) > new Date(room.lastMessageSentAt)) {
          Vue.set(room, 'lastMessageSentAt', message.sentAt);
        }

        if (
          message.from &&
          !message.from.isAgent &&
          message.from.username &&
          message.from.username !== room.username
        ) {
          Vue.set(room, 'username', message.from.username);
        }

        if (message.type === 'text' && message.text !== room.lastMessage) {
          Vue.set(room, 'lastMessage', message.text);
        }

        if (message.channel && message.channel !== room.channel) {
          Vue.set(room, 'channel', message.channel);
        }

        if (!(message.from && message.from.isAgent)) {
          let mobileNumber = message.mobileNumber;
          let email = message.email;

          // Telefone
          if (!mobileNumber && message.from && message.from.mobileNumber) {
            mobileNumber = message.from.mobileNumber;
          }

          // Email
          if (!email && message.contact && message.contact.email) {
            email = message.contact.email;
          }

          // Telefone
          if (!mobileNumber && message.from && message.from.email) {
            mobileNumber = message.from.mobileNumber;
          }

          // Email
          if (!email && message.contact && message.contact.email) {
            email = message.contact.email;
          }

          if (message.from && message.from && message.from.contact) {
            // Telefone
            if (!mobileNumber && message.from && message.from.contact && message.from.contact.mobile_number) {
              mobileNumber = message.from.contact.mobile_number;
            }

            // Email
            if (!email && message.from && message.from.contact && message.from.contact.email) {
              email = message.from.contact.email;
            }
          }

          if (mobileNumber) Vue.set(room, 'mobileNumber', this.maskPhone(mobileNumber));

          if (email) Vue.set(room, 'email', email);
        }
      }

      // console.log('updateFromMessage$1', { message });
      // console.log('updateFromMessage$2', { room });
    },
    updateTotal() {
      const total = this.history.length;

      let opened = 0;
      let closed = 0;

      for (let i = 0; i < total; i += 1) {
        const room = this.history[i];

        if (room.status === 'open') {
          opened += 1;
        } else {
          closed += 1;
        }
      }

      // console.log('$$sortMainHistory', { opened, closed });

      this.hasQueue = opened;
      this.hasHistory = closed;
    },
    preRequestMessages() {
      for (let i = 0; i < this.history.length; i += 1) {
        const roomId = this.history[i].roomId;

        if (this.timeoutMgs[roomId]) clearTimeout(this.timeoutMgs[roomId]);

        this.timeoutMgs[roomId] = setTimeout(() => this.requestMessage(roomId, 'preRequestMessages'), 500);
      }
    },
    requestMessage(roomId, target) {
      // console.log('requestMessage', target);

      this.$live.emit('messages', { perPage: 80, roomId });
    },
    reorderMessages() {
      if (this.messages.length > 1) {
        this.messages = this.messages.sort((a, b) => {
          const result = new Date(a.sentAt) - new Date(b.sentAt);

          if (result < 0) return -1;

          if (result > 0) return 1;

          return 0;
        });
      }
    },
    roomInfo(roomId) {
      const spyRoomId = `spy:${roomId}`;

      // console.log('roomInfo', { roomId }, this.history.findIndex((historyRoom) => historyRoom.roomId === roomId));
      return this.history.find((room) => room.roomId === roomId || room.roomId === spyRoomId);
    },
    launchQueue() {
      if (this.page > 0) return;

      this.page = 1;
      this.ready = true;

      this.emit('history', {
        page: this.page,
        status: 'open',
        all: false,
        perPage: 30,
      });

      const interval = setInterval(() => {
        const store = this.contextApp.$store;
        const path = this.contextApp.$router.history.current.path;

        // desconecta do live se tiver feito logoff
        if (path.indexOf('/login') === 0 || !store.state?.auth?.token) {
          console.log('setInterval');
          clearInterval(interval);
          this.disconnect();
        } else {
          console.log('setInterval', path, !store.state?.auth?.token);
        }

        if (store.state) {
          // const isAgent = store.state.account.is_agent;

          console.log('acceptConnect:', this.acceptConnect(), 'launchQueue');

          if (store.state.account.id !== this.lastAccountId) {
            // Reconecta (quando necessário) se mudar a permissão de usuário
            this.reconnect();
          } else if (this.acceptConnect()) {
            this.connect();
          }
        }
      }, 2000);
    },
    launchReady() {
      this.emit('status');

      const liveStatus = localStorage.getItem('live_status');

      // console.log({ liveStatus });

      if (!liveStatus || liveStatus === 'online') {
        this.online = true;
        this.emit('go-online');
      } else {
        this.ready = true;
      }
    },
    on(event, callback) {
      if (!_events[event]) _events[event] = [];

      _events[event].push(callback);
    },
    trigger(event, value) {
      if (_events[event]) {
        const evts = _events[event];
        const total = evts.length;

        for (let i = 0; i < total; i += 1) {
          setTimeout(evts[i], 1, value);
        }
      }
    },
    emit(type, body) {
      if (this.socket) this.socket.emit(type, body);
    },
    next() {
      // if (this.usersQueue > 0) {
      //   const departmentId = Object.keys(this.queueDepartments).find((queue) => this.queueDepartments[queue] > 0);

      //   console.log({ departmentId });

      //   this.emit('next', {
      //     departmentId: departmentId === 'default' ? null : departmentId,
      //   });
      // }
      // const queue = this.history.find((room) => room.type === 'queue');

      // if (queue) {
      //   this.open(queue);
      //   return queue;
      // }

      // return null;

      // Obtem o ID do primeiro departamento que tiver clientes na fila
      // const message = Object.keys(this.queueDepartments).find((id) => this.queueDepartments[id].size > 0);

      if (this.nextRequesting) return;

      const message = this.myUserId;

      // console.log('[next]', this.userEmail, message, this.queueDepartments);

      if (message) {
        clearTimeout(this.nextTimeout);

        this.nextRequesting = true;

        const user = this.contextApp.$store.state.auth.user.email;
        const accountId = this.contextApp.$store.state.account.id;

        // this.stompClient.send('/app/chat', JSON.stringify({
        //   message,
        //   event: 'next',
        // }), { room: `${user};${accountId}` });
        this.stompClient.send('/app/chat', JSON.stringify({
          message,
          event: 'next',
        }), { room: `${user};${accountId}` });

        this.nextTimeout = setTimeout(() => {
          this.nextRequesting = false;
        }, 2000);
      }
    },
    sendMessage(room, message) {
      let roomId = room.roomId;

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      // console.log('[sendMessage] ->', room, message);
      this.stompClient.send('/app/chat', JSON.stringify({
        message,
        uri: '',
        mediaType: '',
        type: 'text',
        file_size_bytes: 0,
        event: 'send',
      }), { room: roomId });
    },
    rejectRoom(room) {
      let roomId = room.roomId;

      const index = this.history.findIndex((r) => r.roomId === roomId);

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      console.log('rejectRoom', roomId, index);

      if (this.queuedEnabled || index === -1) return;

      const user = this.contextApp.$store.state.auth.user.email;
      const accountId = this.contextApp.$store.state.account.id;

      const entry = this.history.splice(index, 1)[0];

      console.log('my-listing rejected:', entry);

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      this.stompClient.send('/app/chat', JSON.stringify({
        message: roomId,
        event: 'room_refused',
      }), { room: `${user};${accountId}` });
    },
    subscribeAgentRooms(agent, subscribe = true) {
      console.log('subscribeAgentRooms', agent, subscribe);

      if (subscribe) this.requestAgentStatistics(agent);

      // if (agent && agent.id) {
      //   const agentId = agent.id;

      //   console.log('subscribeAgentRooms:main', agentId, subscribe);

      //   const timeouts = this.subscribeTimeouts;

      //   this.history.forEach((room, index) => {
      //     if (room.agentId === agentId && room.status === 'OPEN') { /*  && room.type === 'agent-manager' */
      //       if (timeouts[room.roomId]) clearTimeout(timeouts[room.roomId]);

      //       console.log('subscribeAgentRooms:room', room.roomId, timeouts[room.roomId], subscribe);

      //       if (subscribe && index === 0) {
      //         this.onlyOpen(room);
      //       } else if (subscribe) {
      //         timeouts[room.roomId] = setTimeout(() => {
      //           if (room.agentId === agentId && room.stomp) this.onlyOpen(room);
      //         }, 1000 * index);
      //       } else if (room.stomp) {
      //         this.unsubscribeRoom(room);
      //       }
      //     }
      //   });

      //   console.log('subscribeAgentRooms:end', '\n\n');
      // }
    },
    // onlyOpen(room) {
    //   if (room && room.stomp === null) {
    //     const roomId = room.roomId;
    //     const sub = this.stompClient.subscribe(`/topic/${roomId}/messages`, (message) => {
    //       this.manageRoom(room, message);
    //     });

    //     room.stomp = sub;
    //     Vue.set(room, 'stomp', sub);
    //   }
    // },
    unsubscribeRoom(room) {
      if (room && room.stomp) {
        room.stomp.unsubscribe();
        room.stomp = null;
        Vue.set(room, 'stomp', null);
      }
    },
    // async open(room, from, noupdate = false) {
    //   if (this.queueOpenRooms === null) {
    //     await this.promiseOpen(room, from, noupdate);
    //     return null;
    //   }

    //   this.queueOpenRooms[room.roomId] = { room, from, noupdate };

    //   clearTimeout(this.queueOpenTimeout);

    //   this.queueOpenTimeout = setTimeout(() => this.queueOpen(), 1500);

    //   return null;
    // },
    // async queueOpen() {
    //   const rooms = this.queueOpenRooms;
    //   this.queueOpenRooms = null;

    //   const items = Object.values(rooms);

    //   /* eslint-disable no-await-in-loop */
    //   for (let i = 0; i < items.length; i += 1) {
    //     const item = items[i];

    //     console.log('live:open:in', 'AQUI!');

    //     await this.promiseOpen(item.room, item.from, item.noupdate);
    //     await this.delay(1000);
    //   }
    //   /* eslint-enable no-await-in-loop */
    // },
    open(room, from, noupdate = false) {
      return new Promise((resolve, reject) => {
        if (!room) return resolve('invalid room');

        let roomId = room.roomId;

        if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

        const opened = room.type === 'queue' || room.type === 'me';
        const date = new Date().toString();

        console.log('live:open:in', { from, date, roomId });
        console.log('live:open:out', room.stomp === null, JSON.stringify(room, null, '   '));

        if (room.stomp === null) {
          let doneQueue = false;

          this.toggleLoader(true);

          room.stomp = this.stompClient.subscribe(`/topic/${roomId}/messages`, (message) => {
            if (from === 'queue' && !doneQueue) {
              doneQueue = true;
              this.toggleLoader(false, 200);
            }

            this.manageRoom(room, message);
          });

          // this.toggleLoader(false, 1000);
        }

        if (room.type === 'queue') {
          room.type = 'me';
          room.localStatus = '';

          Vue.set(room, 'type', 'me');
          Vue.set(room, 'localStatus', '');
        }

        if (noupdate !== true && opened) this.callUpdateData();

        this.trigger('$scroll', room.roomId);

        return resolve(roomId);
      });
    },
    close(room) {
      let roomId = room.roomId;

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      this.stompClient.send('/app/chat', JSON.stringify({
        message: 'close',
        uri: '',
        mediaType: '',
        type: 'text',
        file_size_bytes: 0,
        event: 'close',
      }), { room: roomId });
    },
    flagAsClosed(room, localStatus, ignoreFind = false) {
      console.log('close:room', room?.type, room);

      if (!room) return;

      const roomId = room.roomId;

      let sibilingRoomId;

      if (roomId.indexOf('spy:') === 0) {
        sibilingRoomId = roomId.substring(4);
      } else {
        sibilingRoomId = `spy:${roomId}`;
      }

      /* && (transfer || this.managed !== true) */
      if (
        room.type === 'agent-manager' ||
        room.type === 'my-listing' ||
        room.type === 'closed' ||
        room.type === 'me'
      ) {
        switch (localStatus) {
          case 'return_to_queue':
            localStatus = 'queue';
            break;
          case 'transfer_agent':
          case 'transfer_department':
            localStatus = 'transfer';
            break;
          default:
            localStatus = '';
        }

        room.type = 'closed';
        room.status = 'CLOSED';
        room.localStatus = localStatus;

        // Lock

        Vue.set(room, 'localStatus', localStatus);
        Vue.set(room, 'type', 'closed');
        Vue.set(room, 'status', 'CLOSED');

        // this.stompClient.unsubscribe(`/topic/${roomId}/messages`, (message) => {
        //   console.log(`unsubscribe:/topic/${roomId}/messages`, JSON.parse(message.body));
        // });

        this.unsubscribeRoom(room);

        const toRemove = [];

        this.managedAgents.forEach((agent) => {
          const rooms = this.history;
          const agentId = agent.id;
          let total = 0;

          for (let i = rooms.length - 1; i >= 0; i -= 1) {
            const room = rooms[i];

            if (room.agentId === agentId && room.type === 'agent-manager' && room.status === 'OPEN') {
              total += 1;
            }
          }

          Vue.set(agent, 'rooms', total);
        });

        Vue.set(this.newMessages, roomId, 0);

        this.refreshTranscript();
      }

      this.callUpdateData();

      if (ignoreFind !== false) {
        const sibilingRoom = this.history.find((r) => r === sibilingRoomId);
        this.flagAsClosed(sibilingRoom, localStatus, true);
      }
    },
    refreshTranscript() {
      this.associativeTranscriptsHistory = [];
      this.associativeTranscriptsTotal = {};
      this.associativeTranscriptsPage = {};
      this.associativeTranscriptsNoMore = {};
      this.associativeTranscriptsLoading = {};

      this.requestTranscripts('global', this.currentAgent);
    },
    manageMain(message) {
      const data = JSON.parse(message.body);

      // this.contextApp.$toast.show({
      //   title: 'Chat agents',
      //   content: '',
      //   type: 'danger',
      // });

      console.warn('manageRoom:main', { myUserId: this.myUserId }, data);

      // this.online = true;

      if (
        !this.myUserId &&
        'agent_departments' in data &&
        data.user && data.user.userType === 'AGENT'
      ) {
        // console.log('live.service.js', data.user);
        this.myUserId = data.id;
        this.status = data.status;

        data.user.profiles.forEach((profile) => {
          if (profile.name === 'administrador') {
            this.profiles.push('ADMINISTRATOR');
          } else {
            this.profiles.push(profile.name.toUpperCase());
          }
        });

        console.log('live.service.js', this.profiles);

        this.callStatusTime(data.status_date, false);
        this.restoreSubscribes();
      }

      if (data.type === 'room_refused') {
        const room = this.roomInfo(data.id);

        console.warn('manageRoom:main+extras', { room }, { data });

        if (room) {
          Vue.set(room, 'status', 'CLOSED');
          Vue.set(room, 'localStatus', 'queue');
          Vue.set(room, 'type', 'closed');
          Vue.set(room, 'status', 'CLOSED');
        }
      } else if (data.type === 'refresh-system') {
        console.info('refresh-system');
        this.reconnect();
      } else if (data.type === 'agents-manager') {
        const openRooms = [];

        console.log('manageRoom:agents-manager', data.agents);

        data.agents.forEach((entry) => {
          const agent = entry.agent;
          const agentId = agent.id;
          const rooms = entry.rooms;

          // console.log('manageRoom:profiles(agent.user.profiles)', agent.user.profiles);

          if (agent.user.profiles.findIndex((profile) => profile.name === 'agent') !== -1) {
            // rooms = [
            //   {
            //     type: 'me',
            //     room_id: 'aaa',
            //     client_id: 'room.client_id',
            //     channel: 'whatsapp',
            //     username: 'room.name',
            //     email: 'room.email',
            //     mobile_number: 'room.phone',
            //     protocol: 'room.protocol',
            //     createdAt: 'room.creation_date',
            //     closedAt: 'room.close_date',
            //     tabId: null,
            //     requireTab: false,
            //     lastMessage: '',
            //     lastMessageSentAt: '',
            //     stomp: null,
            //   },
            // ];

            this.putManagedAgent(agent, rooms.length);

            // console.log('entry [agent, rooms]', { agent, rooms });

            if (rooms.length) {
              rooms.forEach((room) => {
                if (room.status === 'OPEN' || room.status === 'QUEUE') {
                  openRooms.push(room.id);
                  this.putRoom(room, room.type, true);
                }
              });
            }
          }
        });

        // console.log('managedAgents:agents-manager', this.managedAgents);

        // console.log('manageRoom:agents-manager -> flagAsClosed', openRooms);

        // this.history.forEach((room) => {
        //   if (room.type === 'agent-manager' && openRooms.indexOf(room.roomId) === -1) {
        //     this.flagAsClosed(room);
        //   }
        // });

        this.callUpdateManaged();
      } else if (data.type === 'real-time') {
        let administer = [];
        const accountId = this.accountId;

        clearInterval(this.administerUpdateTimeout);

        if (Array.isArray(data.departments)) {
          if (data.departments.length === 1) {
            administer = data.departments;

            if (administer[0].department === accountId) administer[0].generic = true;
          } else {
            administer = data.departments.sort((a, b) => {
              console.log('administerUpdateTimeout', a.department, accountId);

              if (a.department === accountId) {
                a.generic = true;
                return -1;
              }

              if (b.department === accountId) {
                b.generic = true;
                return 1;
              }

              const compare = a.department.localeCompare(b.department, 'pt', { ignorePunctuation: true });

              if (compare < 0) return -1;

              if (compare > 0) return 1;

              return 0;
            });
          }
          // console.log({ administer });
        }

        this.administer = administer;

        if (administer.length) this.administerUpdate();
      } else if (data.event === 'transfer_finished') {
        console.info('transfer_finished', data);
        const roomId = data.room_id;

        if (roomId) {
          const croom = this.roomInfo(roomId);
          Vue.set(croom, 'locked', false);
          this.flagAsClosed(croom);
        }
      } else if (data.event === 'request_disconnect') {
        this.disconnect(true);
      } else if (data.room_id && data.type !== 'agent-manager') {
        if (data.status !== 'QUEUE') return;

        if (data.agent_id === this.myUserId) {
          console.log('putRoom room_id in data', data);

          const roomId = data.room_id;
          const noExists = this.history.findIndex((r) => r.roomId === roomId) === -1;
          const room = this.putRoom(data, 'me');

          this.open(room, 'queue');
          this.requestFromOffline();

          if (noExists && this.nextRequesting) {
            this.contextApp.$root.$emit('live:open', room);
          }
        } else {
          this.next();
        }

        this.callUpdateData();
      } else if ('queue' in data) {
        let queues = 0; // Soma todos departamentos

        clearTimeout(this.nextTimeout);
        this.nextRequesting = false;
        this.queueDepartments = {};

        data.queue.forEach((entry) => {
          if (!this.queueDepartments[entry.department_id]) {
            queues += entry.size;
            this.queueDepartments[entry.department_id] = entry;
          }
        });

        console.log('[queueTotal]', queues, this.queueTotal);

        if (queues > this.queueTotal) {
          this.queueTotal = queues;
          this.notify(true);
        } else {
          this.queueTotal = queues;
        }
      } else if (
        'room_id' in data && data.room_id === null &&
        'type' in data && data.type === null &&
        'id' in data && data.id === null
      ) {
        this.queueLimited = true;
        this.$toast.show({
          title: 'Atendimento',
          type: 'info',
          content: 'Você atingiu o limite de atendimentos simultâneos, assim que terminar uma dos antedimentos atuais, você pode atender a próxima',
        });
      } else if (Array.isArray(data)) {
        console.log('STOMP-INPUT#1', data);
        data.forEach((entry) => {
          const type = entry.type;

          console.log('STOMP-INPUT#2', type, entry.transfer, entry);

          if (type === 'my-listing') {
            this.putRoom(entry, type);
          } else if ('rooms' in entry) {
            entry.rooms.forEach((entry) => {
              if (entry.type !== 'idle') {
                const roomId = entry.room_id;
                const noExists = this.history.findIndex((r) => r.roomId === roomId) === -1;
                const room = this.putRoom(entry, type);

                console.log('STOMP-INPUT (forEach)', room.agentId, this.myUserId);
              }

              // if (noExists && room.agent_id === this.myUserId) this.contextApp.$root.$emit('live:open', room);
            });
          } else if (entry.transfer) {
            this.transfered(entry);
          } else if (entry.room_id || entry.type === 'client_off') {
            const fromOffline = entry.type === 'client_off';
            const roomId = fromOffline ? entry.client_off_id : entry.room_id;

            let noExists;

            if (fromOffline) {
              noExists = false;
            } else {
              noExists = this.history.findIndex((r) => r.roomId === roomId) === -1;
            }

            const room = this.putRoom(entry, type);

            console.log(`STOMP-INPUT (entry.room_id):${type}`, room.agentId, this.myUserId, type, entry);

            // if (noExists && room.agentId === this.myUserId) {
            //   this.contextApp.$root.$emit('live:open', room);
            // }
          }
        });

        this.callUpdateData();
      } else if (data.type === 'client_off' || data.type === 'idle') {
        if (data.type === 'client_off') {
          this.history = this.history.filter((room) => !room.fromOffline);
        } else {
          this.history = this.history.filter((room) => !room.fromIdle);
        }

        const items = data.data ?? data.rooms;

        items.forEach((entry) => {
          this.putRoom(entry, data.type);
        });
      } else if ('id' in data && 'type' in data) {
        switch (data.type) {
          case 'transfer_agent':
          case 'transfer_department':
          case 'return_queue':
          case 'close':
            this.flagAsClosed(this.roomInfo(data.id), data.type);
            break;
          default:
            console.error('Invalid room operation for:', data.id, 'Operation:', data.type);
        }
      }
    },
    manageRoom(room, message) {
      const roomId = room.roomId;
      const data = JSON.parse(message.body);
      const evt = data.event;

      console.log(`manageRoom:roomId:${roomId}`, evt, data);

      this.queueLimited = false;

      if (!evt || evt === 'historic' || evt === 'close' || evt === 'room_closed' || evt === 'greeting') {
        // console.log('message:/topic/appendMessage');
        if (evt !== 'close' && evt !== 'room_closed') {
          this.appendMessage(room, data);

          // if (data.status !== 'FAIL') {
          //   this.appendMessage(room, data);
          // } else {
          //   this.$toast.show({
          //     id: 'live',
          //     title: 'Upload',
          //     type: 'danger',
          //     content: 'O arquivo que tentou enviar é muito grande',
          //   });
          // }
        } else {
          if (data.message && data.message.indexOf('Error') !== -1) {
            this.$toast.show({
              title: 'Tabulação',
              type: 'danger',
              content: 'Antes de finalizar adicione uma tabulação',
            });
            return;
          }

          setTimeout(() => {
            this.flagAsClosed(room);
            this.refreshTranscript();
          }, 1);
        }
      } else {
        switch (evt) {
          case 'delivered':
          case 'read':
            this.updateMessage(evt, room, data);
            break;
          // case 'close':
          //   // console.log('message:/topic/close', evt);
          //   this.flagAsClosed(room);
          //   break;
          case 'return_to_queue':
          case 'transfer_agent':
          case 'transfer_department':
            this.flagAsClosed(room, evt);
            break;
          case 'subscribed':
            this.toggleLoader(false, 200);
            Vue.set(room, 'status', 'OPEN');
            break;
          default:
            console.error({
              evt,
              roomId,
              messageId: data.id,
              data,
            });
        }
      }
    },
    async requestAgentStatistics(agent) {
      console.log({ agent });
      const agentId = agent.id;

      try {
        const date = new Date();
        const y = date.getFullYear();

        let m = date.getMonth() + 1;
        let d = date.getDate();

        if (m < 10) m = `0${m}`;

        if (d < 10) d = `0${d}`;

        const dates = `${y}-${m}-${d}T`;
        const beginDate = encodeURIComponent(`${dates}00:00:00`);
        const endDate = encodeURIComponent(`${dates}23:59:59`);

        const response = await webApi.get(`/omni/agents/${agentId}/statistic?begin=${beginDate}&end=${endDate}`);

        const statistics = {
          actives: response.data.active_calls ?? 0,
          time: response.data.average_service_time ?? null,
          total: response.data.total_calls ?? 0,
        };

        Vue.set(agent, 'statistics', {});
        Vue.set(agent.statistics, 'actives', statistics.actives);
        Vue.set(agent.statistics, 'time', statistics.time);
        Vue.set(agent.statistics, 'total', statistics.total);
      } catch (ee) {
        this.contextApp.$toast.show({
          title: 'Estatisticas',
          content: String(ee),
          type: 'danger',
        });
      }
    },
    async requestAgents(ignoreAgentId) {
      if (this.agentsLoading) return;

      this.agentsLoading = true;
      // this.emit('agent-list');
      try {
        const response = await webApi.get('webchat/agents');
        // console.log('$live:requestAgents', response.data);
        this.agents = response.data?.length ? response.data.filter((agent) => {
          if (agent.id === ignoreAgentId) return false;

          console.log('agent!!!', agent);

          const profiles = agent?.user?.profiles;

          if (!profiles) return false;

          return profiles.some((profile) => profile.name === 'agent');
        }) : [];
      } catch (ee) {
        this.contextApp.$toast.show({
          title: 'Chat agents',
          content: String(ee),
          type: 'danger',
        });
      }

      this.agentsLoading = false;
    },
    async requestDepartments() {
      if (this.departmentsLoading) return;

      this.departmentsLoading = true;
      // this.emit('agent-list');
      try {
        const response = await webApi.get('webchat/departments/on');
        // console.log('$live:requestDepartments', response.data);
        this.departments = response.data;
      } catch (ee) {
        this.contextApp.$toast.show({
          title: 'Chat agents',
          content: String(ee),
          type: 'danger',
        });
      }

      this.departmentsLoading = false;
    },
    async requestAccountTags() {
      if (this.accountController) return;

      try {
        this.accountController = new AbortController();

        const response = await webApi.get('webchat/account-tags', {
          signal: this.accountController.signal,
        });

        console.log('$live:requestAccountTags', response.data);

        if (Array.isArray(response.data)) {
          let tags = response.data;

          if (Array.isArray(tags)) {
            tags = tags.filter((tag) => typeof tag === 'string' && tag !== '');
          } else {
            tags = [];
          }

          this.accountTags = tags;
        }
      } catch (ee) {
        console.error('$live:requestAccountTags', ee);
      }

      // Simulate
      // this.accountTags = ['abc', 'abce', 'ab123', 'bcaab', 'ddd', 'ddd1', 'ddd2', 'ddd3', 'ddd4'];

      this.accountController = null;
    },
    async abortAccountTags() {
      if (this.accountController) this.accountController.abort();
    },
    openNow(room) {
      if (room.status === 'OPEN') {
        return Promise.reject(new Error({
          message: 'invalid room',
          room,
        }));
      }

      // const accountId = this.contextApp.$store.state.account.id;
      // const accountId = '4c4e7ee7-7208-4dca-a4b3-568711840bba';

      const payload = { agent_id: this.myUserId };

      if (room.fromOffline) {
        payload.client_off_id = room.clientId;
      } else {
        payload.client_id = room.clientId;
      }

      payload.account_external_id = room.accountExternalId;
      payload.client_external_id = room.clientExternalId;

      return webApi.post(`webchat/resume/now/${room.channel}`, payload).then(() => {
        this.markAsSolved(room);
      });
    },
    openIdleNow(room) {
      if (!room.fromIdle) return Promise.reject(new Error({ message: 'invalid room', room }));

      // const accountId = this.contextApp.$store.state.account.id;
      // const accountId = '4c4e7ee7-7208-4dca-a4b3-568711840bba';
      const email = room.contact?.email || room.email;
      const phone = room.contact?.mobileNumber || room.phone;

      const payload = {
        agent_id: this.myUserId,
        client_id: room.clientId,
        external_account_id: room.accountExternalId,
        external_client_id: room.clientExternalId,
        room_id: room.roomId,
        email,
        phone,
      };

      return webApi.post(`webchat/resume/idle/${room.channel}`, payload).then(() => {
        Vue.set(room, 'fromIdle', false);
        this.markAsSolved(room);
      });
    },
    async updateTags(room) {
      const tags = room.tags.filter((tag) => tag !== null && tag !== '' && typeof tag !== 'undefined');
      const originalTags = room.originalTags ?? [];
      const payload = { tags, originalTags };

      let roomId = room.roomId;

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      await webApi.put(`webchat/room-tags/${roomId}`, payload);

      Vue.set(room, 'originalTags', room.tags);

      const accountTags = this.accountTags;

      tags.forEach((tag) => {
        if (accountTags.indexOf(tag) === -1) {
          accountTags.push(tag);
        }
      });
    },
    async requestTabs() {
      if (this.tabsLoading) return;

      this.tabsLoading = true;
      // this.emit('agent-list');
      try {
        const response = await webApi.get('webchat/tabs');
        console.log('$live:requestTabs', response.data);
        this.tabs = response.data?.filter((tab) => tab.name !== 'GENERIC') ?? [];
      } catch (ee) {
        console.error('$live:requestTabs', ee);
        this.contextApp.$toast.show({
          title: 'Chat agents',
          content: String(ee),
          type: 'danger',
        });
      }

      this.tabsLoading = false;
    },
    async resetTranscripts(parentRoomId) {
      this.associativeTranscriptsHistory = this.associativeTranscriptsHistory.filter((room) => room.parentRoomId.indexOf(parentRoomId) === -1);

      console.log('webchat:2', this.associativeTranscriptsHistory.length);

      Vue.set(this.associativeTranscriptsPage, parentRoomId, 0);
      Vue.set(this.associativeTranscriptsTotal, parentRoomId, 0);
      Vue.set(this.associativeTranscriptsNoMore, parentRoomId, false);
      Vue.set(this.associativeTranscriptsLoading, parentRoomId, false);
    },
    requestHistoricRoom(roomId) {
      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      this.stompClient.send('/app/chat', JSON.stringify({
        message: roomId,
        event: 'send-historic',
      }), { room: roomId });
    },
    async requestFromOffline() {
      if (this.requestingFromOffline) return;

      this.requestingFromOffline = true;

      setTimeout(() => {
        this.requestingFromOffline = false;
      }, 2000);

      const message = this.myUserId;
      const user = this.contextApp.$store.state.auth.user.email;
      const accountId = this.contextApp.$store.state.account.id;

      // this.stompClient.send('/app/chat', JSON.stringify({
      //   message,
      //   event: 'send_client_off',
      // }), { room: `${user};${accountId}` });
      this.requestTranscripts('offline', null);
    },
    async requestIdle() {
      const roomId = 'idle';

      if (this.associativeTranscriptsLoading[roomId] || this.associativeTranscriptsNoMore[roomId]) return;

      Vue.set(this.associativeTranscriptsLoading, roomId, true);

      if (!(roomId in this.associativeTranscriptsPage)) {
        Vue.set(this.associativeTranscriptsPage, roomId, 0);
      }

      const associative = this.associativeTranscriptsHistory;
      const page = this.associativeTranscriptsPage[roomId] ?? 0;
      const pageSize = 20;

      // console.info('associative', associative);

      const path = `webchat/idle?size=${pageSize}&page=${page}`;

      // this.emit('agent-list');
      try {
        const response = await webApi.get(path);

        const transcripts = response.data.content;

        console.info('associative:transcripts', transcripts);

        Vue.set(this.associativeTranscriptsPage, roomId, page + 1);
        Vue.set(this.associativeTranscriptsNoMore, roomId, transcripts.length < pageSize);

        if (roomId in this.associativeTranscriptsTotal) {
          Vue.set(this.associativeTranscriptsTotal, roomId, this.associativeTranscriptsTotal[roomId] + transcripts.length);
        } else {
          Vue.set(this.associativeTranscriptsTotal, roomId, transcripts.length);
        }

        if (transcripts.length) {
          transcripts.forEach((entry) => {
            const roomTranscriptId = entry.id ?? entry.client_id;
            const exists = associative.find((r) => r.roomId === roomTranscriptId);
            const tags = Array.isArray(entry.tags) ? entry.tags.map((tag) => tag?.name) : [];

            console.log(':TAGS', 'entry.tags:', entry.tags, 'tags.map:', tags);

            let lastMessage = entry.last_message?.message || '';
            let sessionTime = new Date();

            if (lastMessage) lastMessage = lastMessage.trim().replace(this.accountToGeneral, 'Geral');

            if (entry.session_minutes) sessionTime -= (entry.session_minutes * 1000 * 60);

            if (exists) {
              exists.parentRoomId.push(roomId);
            } else {
              const firstName = entry?.contact?.['first_name'] || '';
              const lastName = entry?.contact?.['last_name'] || '';
              const username = firstName && lastName ? `${firstName} ${lastName}` : (
                entry.username ?? entry.name ?? '---'
              );

              const newRoom = {
                tags,
                type: entry.type,
                roomId: roomTranscriptId,
                parentRoomId: [roomId],
                clientId: entry.client_id ?? entry.id,
                channel: entry.channel || entry.from,
                username,
                email: entry.email,
                mobileNumber: entry.phone,
                protocol: entry.protocol,
                createdAt: entry.creation_date,
                closedAt: entry.close_date,
                tabId: entry.tabulation_id,
                lastMessage,
                lastMessageSentAt: entry.last_message_sent || entry.creation_date,
                session: entry.session,
                sessionMinutes: entry.session_minutes,
                sessionTime,
                fromTranscripts: true,
                fromOffline: false,
                fromIdle: true,
                accountExternalId: entry.identification_account ?? entry.account_external_identification ?? null,
                clientExternalId: entry.identification_external ?? entry.client_external_id ?? null,
                contact: {
                  id: entry?.contact?.id,
                  gender: entry?.contact?.gender,
                  city: entry?.contact?.city,
                  country: entry?.contact?.country,
                  description: entry?.contact?.description,
                  company: entry?.contact?.company,
                  address: entry?.contact?.address,
                  number: entry?.contact?.number,
                  complement: entry?.contact?.complement,
                  district: entry?.contact?.district,
                  zip: entry?.contact?.zip,
                  state: entry?.contact?.state,
                  firstName: entry?.contact?.['first_name'],
                  lastName: entry?.contact?.['last_name'],
                  birthDate: entry?.contact?.['birth_date'],
                  phone: entry?.contact?.phone,
                  mobileNumber: entry?.contact?.['mobile_number'],
                  email: entry?.contact?.email,
                },
              };

              console.warn('putRoom:transcript', newRoom);

              associative.push(newRoom);
            }
          });
        }

        console.log('associativetotal', this.associativeTranscriptsTotal[roomId], {
          roomId,
          associative,
        });
      } catch (ee) {
        this.contextApp.$toast.show({
          title: 'Chat transcripts',
          content: String(ee),
          type: 'danger',
        });
      }

      Vue.set(this.associativeTranscriptsLoading, roomId, false);
    },
    async requestTranscripts(room, agent) {
      let roomId;

      if (room.roomId) {
        roomId = room.roomId;

        if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);
      } else {
        roomId = room;
      }

      let email;
      let mobileNumber;

      if (typeof room !== 'string') {
        email = room.contact.email;
        mobileNumber = room.contact?.['mobile_number'] ?? room.contact?.mobileNumber;
      }

      console.log('associativeHistory', { room, email, mobileNumber });

      if (roomId.indexOf('global') !== 0 && roomId !== 'offline') {
        if ((!email || email === '-') && (!mobileNumber || mobileNumber === '-')) {
          return;
        }
      }

      // console.log('associativeHistory:requestTranscripts', {
      //   roomId, email, mobileNumber,
      // });

      if (this.associativeTranscriptsLoading[roomId] || this.associativeTranscriptsNoMore[roomId]) return;

      Vue.set(this.associativeTranscriptsLoading, roomId, true);

      if (!(roomId in this.associativeTranscriptsPage)) {
        Vue.set(this.associativeTranscriptsPage, roomId, 0);
      }

      const associative = this.associativeTranscriptsHistory;
      const page = this.associativeTranscriptsPage[roomId] ?? 0;
      const fromOffline = roomId === 'offline';
      const pageSize = 20;

      // console.info('associative', associative);

      let path;

      if (fromOffline) {
        path = `webchat/offlines?size=${pageSize}&page=${page}`;
        // path = `webchat/transcripts?size=${pageSize}&page=${page}`;
      } else {
        path = `webchat/transcripts?size=${pageSize}&page=${page}`;
      }

      // path += `&source=${encodeURIComponent(roomId)}`;

      if (typeof room !== 'string') {
        if (email) path += `&email=${encodeURIComponent(email)}`;

        if (mobileNumber) path += `&phone=${encodeURIComponent(mobileNumber)}`;

        if (!email && !mobileNumber) {
          Vue.set(this.associativeTranscriptsTotal, roomId, 0);
          Vue.set(this.associativeTranscriptsNoMore, roomId, false);
          Vue.set(this.associativeTranscriptsLoading, roomId, false);
          return;
        }
      }

      const role = this.contextApp.$store.state.account.role;

      if (roomId === 'global' && role !== 'admin' && role !== 'owner') {
        const user = this.contextApp.$store.state.auth.user.email;
        path += `&agent=${encodeURIComponent(user)}`;
      }

      if (!fromOffline && agent && agent.email) {
        path += `&agent=${encodeURIComponent(agent.email)}`;

        if (roomId === 'global') roomId += `:${agent.id}`;
      }

      console.info('roomId', roomId);

      // this.emit('agent-list');
      try {
        const response = await webApi.get(path);

        const transcripts = response.data.content;

        console.info('associative:transcripts', transcripts);

        Vue.set(this.associativeTranscriptsPage, roomId, page + 1);
        Vue.set(this.associativeTranscriptsNoMore, roomId, transcripts.length < pageSize);

        if (roomId in this.associativeTranscriptsTotal) {
          Vue.set(this.associativeTranscriptsTotal, roomId, this.associativeTranscriptsTotal[roomId] + transcripts.length);
        } else {
          Vue.set(this.associativeTranscriptsTotal, roomId, transcripts.length);
        }

        if (transcripts.length) {
          transcripts.forEach((entry) => {
            const roomTranscriptId = fromOffline ? entry.client_off_id : entry.room_id;
            const exists = associative.find((r) => r.roomId === roomTranscriptId);
            const tags = Array.isArray(entry.tags) ? entry.tags.map((tag) => tag?.name) : [];

            console.log(':TAGS', 'entry.tags:', entry.tags, 'tags.map:', tags);

            let lastMessage = entry.last_message?.message || '';
            let sessionTime = new Date();

            if (lastMessage) lastMessage = lastMessage.trim().replace(this.accountToGeneral, 'Geral');

            if (entry.session_minutes) sessionTime -= (entry.session_minutes * 1000 * 60);

            if (exists) {
              exists.parentRoomId.push(roomId);
            } else {
              const firstName = entry?.contact?.['first_name'] || '';
              const lastName = entry?.contact?.['last_name'] || '';
              const username = firstName && lastName ? `${firstName} ${lastName}` : (
                entry.username ?? entry.name ?? '---'
              );

              const newRoom = {
                tags,
                type: entry.type,
                roomId: roomTranscriptId,
                parentRoomId: [roomId],
                clientId: entry.client_id ?? entry.id,
                channel: entry.channel || entry.from,
                username,
                email: entry.email,
                mobileNumber: entry.phone,
                protocol: entry.protocol,
                createdAt: entry.creation_date,
                closedAt: entry.close_date,
                tabId: entry.tabulation_id,
                lastMessage,
                lastMessageSentAt: entry.last_message_sent || entry.creation_date,
                session: entry.session,
                sessionMinutes: entry.session_minutes,
                sessionTime,
                fromTranscripts: true,
                fromOffline,
                accountExternalId: entry.identification_account ?? entry.account_external_identification ?? null,
                clientExternalId: entry.identification_external ?? entry.client_external_id ?? null,
                contact: {
                  id: entry?.contact?.id,
                  gender: entry?.contact?.gender,
                  city: entry?.contact?.city,
                  country: entry?.contact?.country,
                  description: entry?.contact?.description,
                  company: entry?.contact?.company,
                  address: entry?.contact?.address,
                  number: entry?.contact?.number,
                  complement: entry?.contact?.complement,
                  district: entry?.contact?.district,
                  zip: entry?.contact?.zip,
                  state: entry?.contact?.state,
                  firstName: entry?.contact?.['first_name'],
                  lastName: entry?.contact?.['last_name'],
                  birthDate: entry?.contact?.['birth_date'],
                  phone: entry?.contact?.phone,
                  mobileNumber: entry?.contact?.['mobile_number'],
                  email: entry?.contact?.email,
                },
              };

              console.warn('putRoom:transcript', newRoom);

              associative.push(newRoom);
            }
          });
        }

        console.log('associativetotal', this.associativeTranscriptsTotal[roomId], {
          roomId,
          associative,
        });
      } catch (ee) {
        this.contextApp.$toast.show({
          title: 'Chat transcripts',
          content: String(ee),
          type: 'danger',
        });
      }

      Vue.set(this.associativeTranscriptsLoading, roomId, false);
    },
    async requestTranscriptMessages(room, fromIdle = false) {
      console.log('requestTranscriptMessages', room);

      let roomId = room.roomId;

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      const associative = this.associativeTranscriptsMessages;

      const fromOffline = room.fromOffline === true;

      if (!this.associativeTranscriptsTotal[roomId]) {
        this.toggleLoader(true);
      } else {
        this.toggleLoader(false);
      }

      try {
        let path;

        if (fromOffline) {
          path = `webchat/offlines/${roomId}/messages`;
        } else if (fromIdle) {
          path = `webchat/idle/${roomId}/messages`;
        } else {
          path = `webchat/transcripts/${roomId}/messages`;
        }

        const response = await webApi.get(path);
        const transcriptsMsgs = response.data;

        // console.log('$live:requestTranscriptMessages', transcriptsMsgs);

        if (transcriptsMsgs.length) {
          transcriptsMsgs.forEach((entry) => {
            const msgId = entry.id ?? entry.id_external;

            if (!associative.some((msg) => msg.id === msgId)) {
              associative.push(this.generateMessage(roomId, room, entry));
            }
          });
        }
      } catch (ee) {
        this.contextApp.$toast.show({
          title: 'Chat transcripts',
          content: String(ee),
          type: 'danger',
        });
      }

      this.toggleLoader(false, 200);
    },
    transferToAgent(room, agentId) {
      if (room?.roomId && !room.locked) {
        // Vue.set(room, 'locked', true);

        let roomId = room.roomId;

        if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

        this.stompClient.send('/app/chat', JSON.stringify({
          message: agentId,
          event: 'transfer_agent',
        }), { room: roomId });

        this.flagAsClosed(room);
      }
    },
    transferToDepartment(room, departmentId) {
      if (room?.roomId && !room.locked) {
        Vue.set(room, 'locked', true);

        let roomId = room.roomId;

        if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

        this.stompClient.send('/app/chat', JSON.stringify({
          message: departmentId,
          event: 'transfer_department',
        }), { room: roomId });

        // this.flagAsClosed(room);
      }
    },
    transferToQueue(room) {
      if (room?.roomId && !room.locked) {
        // Vue.set(room, 'locked', true);

        let roomId = room.roomId;

        if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

        this.stompClient.send('/app/chat', JSON.stringify({
          event: 'return_to_queue',
        }), { room: roomId });

        this.flagAsClosed(room);
      }
    },
    notify(enable) {
      // this.hasNotifications = enable;
      // this.contextApp.$root.$emit('live:notify', enable);
      this.contextApp.$root.$emit('live:notify', this.hasNotifications);
    },
    userHistory(contactId) {
      this.emit('user-history', {
        contactId,
      });
    },
    async changeTabRoom(room, tabId) {
      this.tabsLoading = true;
      let roomId = room.roomId;

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      console.log(`webchat/tabs/${tabId}/room`, roomId);

      return webApi.put(`webchat/tabs/${tabId}/room`, { roomId }).then((data) => {
        Vue.set(room, 'tabId', tabId);
      }).finally(() => {
        this.tabsLoading = false;
      });
    },
    async updateClient(room, field, value) {
      console.log('updateClient', room);

      const id = room.contact.id;
      const payload = {};

      const change = {
        firstName: 'first_name',
        lastName: 'last_name',
        birthDate: 'birth_date',
        mobileNumber: 'mobile_number',
      };

      if (change[field]) field = change[field];

      return webApi.put(`webchat/contact/${id}`, { field, value });
    },
    updateContact(type, roomId, contactId, value) {
      const payload = {};

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      switch (type) {
        case 'name':
          value = value.trim();

          const pos = value.indexOf(' ');

          payload.first_name = value.substring(0, pos);
          payload.last_name = value.substring(pos + 1).trim();
          break;
        case 'phone':
          payload.mobile_number = value;
          break;
        case 'mail':
          payload.email = value;
          break;
        default:
          console.error('Invalid type');
      }

      return webApi.put(`contacts/${contactId}`, payload).then((data) => {
        const room = this.history.find((hr) => hr.roomId === roomId || hr.roomId === `spy:${roomId}`);

        if (type === 'name') Vue.set(room, 'username', value);

        if (type === 'phone') Vue.set(room, 'mobileNumber', value);

        if (type === 'mail') Vue.set(room, 'email', value);

        return Promise.resolve(data);
      });
    },
    async updateClientContact(room, payload) {
      const contact = room?.contact;
      const contactId = contact?.id;

      console.log(`editFields/contacts/${contactId}`, room?.contact);

      let roomId = room?.roomId;

      if (!roomId || !contactId) throw new Error('Invalid contact');

      if (roomId.indexOf('spy:') === 0) roomId = roomId.substring(4);

      return webApi.put(`contacts/${contactId}`, payload).then((data) => {
        Object.keys(payload).forEach((key) => {
          contact[key] = payload[key];
        });

        return data;
      });
    },
    maskPhone(phone) {
      if (!phone) return '';

      phone = phone.replace(/\D+/g, '').substring(0, 13);

      switch (phone.length) {
        case 13:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 12:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4');
          break;
        case 11:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        case 10:
          phone = phone.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+55 ($2) $3-$4');
          break;
        default:
      }

      return phone;
    },
    markdown(value, breakBr) {
      if (!this.markdownDom) this.markdownDom = document.createElement('div');

      this.markdownDom.textContent = value;

      const data = this.markdownDom.innerHTML
        .replace(/\0/g, '')
        .replace(/_([^_]+?)_/g, '<em>$1</em>')
        .replace(/\*([^*]+?)\*/g, '<strong>$1</strong>')
        .replace(/[~]([^~]+?)[~]/g, '<strike>$1</strike>')
        .replace(/[`]{3}([\s\S]+?)[`]{3}/g, '<code>$1</code>')
        .replace(/\[([^\]]+?)\]/g, '<span data-interactive="true">$1</span>');

      if (breakBr) return data.replace(/(\r\n|\n)/g, '<br>');

      return data;

      // .replace(/(^|\s)_([^_]+?)_($|\s)/g, '$1<em>$2</em>$3')
      // .replace(/(^|\s)\*([^*]+?)\*($|\s)/g, '$1<strong>$2</strong>$3')
      // .replace(/(^|\s)[~]([^~]+?)[~]($|\s)/g, '$1<strike>$2</strike>$3')
      // .replace(/(^|\s)[`]{3}(.*?)[`]{3}($|\s)/g, '$1<code>$2</code>$3');
    },
    requestSampleMessages() {
      if (this.sampleMessagesLoading) return;

      this.sampleMessagesLoading = true;

      webApi.get(`webchat/samples/${this.myUserId}`).then((response) => {
        this.sampleMessagesLoading = false;
        this.sampleMessages = response?.data ?? [];
        this.requestDepartments();
        return Promise.resolve();
      }).catch((error) => {
        this.sampleMessagesLoading = false;
        return Promise.reject(error);
      });
    },
    createSampleMessage(key, message, availableTo) {
      let forAll = false;
      let departmentId = null;

      if (availableTo === 'all') {
        forAll = true;
      } else if (availableTo !== 'me' && availableTo) {
        departmentId = availableTo;
      }

      const payload = {
        agent_id: this.myUserId,
        department_id: departmentId,
        for_all: forAll,
        key,
        message,
      };

      return webApi.post('webchat/samples/create', payload).then(() => {
        this.contextApp.$toast.show({
          title: 'Sucesso',
          content: 'Mensagem cadastrada',
          type: 'success',
        });

        this.requestSampleMessages();

        return Promise.resolve();
      }).catch((error) => {
        this.contextApp.$toast.show({
          title: `Erro ${error.code}`,
          content: error.message,
          type: 'danger',
        });

        return Promise.reject(error);
      });
    },
    changeMute(type) {
      let time = 0;

      if (type === '8_hours') {
        time = 8 * 60 * 60 * 1000;
        // time = 2 * 1000;
        type = `${type}:`;
      } else if (type === '1_week') {
        time = 7 * 24 * 60 * 60 * 1000;
        // time = 7 * 1000;
        type = `${type}:`;
      } else if (type === '1_month') {
        time = 30 * 24 * 60 * 60 * 1000;
        // time = 30 * 1000;
        type = `${type}:`;
      } else {
        time = '';
      }

      if (time > 0) {
        const date = new Date();
        date.setTime(date.getTime() + time);
        time = date.getTime();
      }

      const current = `${type}${time}`;
      localStorage.setItem('live-mute', current);
      this.refreshMute(current);
    },
    refreshMute(current) {
      const date = new Date();

      if (current === 'always') {
        this.muteTime = 0;
        this.muteStatus = 'always';
        this.isMuted = true;
      } else if (!current) {
        current = localStorage.getItem('live-mute');
      }

      if (!current) current = '';

      if (current.indexOf('8_hours:') === 0) {
        date.setTime(Number(current.substr(8)));
        this.muteTime = date.getTime();
        this.muteStatus = '8_hours';
        this.isMuted = true;
      } else if (current.indexOf('1_week:') === 0) {
        date.setTime(Number(current.substr(7)));
        this.muteTime = date.getTime();
        this.muteStatus = '1_week';
        this.isMuted = true;
      } else if (current.indexOf('1_month:') === 0) {
        date.setTime(Number(current.substr(8)));
        this.muteTime = date.getTime();
        console.log('current.substr(8)', Number(current.substr(8)), this.muteTime);
        this.muteStatus = '1_month';
        this.isMuted = true;
      } else {
        this.muteTime = 0;
        this.muteStatus = 'disabled';
        this.isMuted = false;
      }

      setTimeout(() => this.refreshMute(), 60000);
    },
    delay(timeout) {
      return new Promise((resolve) => setTimeout(() => resolve(new Date()), timeout));
    },
    async restoreSubscribes() {
      const toRestore = this.history.filter((room) => {
        console.log('restoreSubscribes', room);

        return room.type === 'me' && room.status === 'OPEN' && room.session === 'opened';
      });

      console.log('restoreSubscribes', {
        history: this.history,
        restore: toRestore,
      });

      toRestore.forEach(async (room) => {
        room.stomp = null;
        console.log('restoreSubscribes', room, new Date().toString());
        await this.open(room);
        await this.delay(1000);
      });
    },
    // others
    activeWhatsApp360(active) {
      console.log('activeWhatsApp360', active);
      this.whatsApp360 = active;
    },
  },
});
